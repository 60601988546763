import React from 'react'
import MethodOfExecutionCard from './MethodOfExecutionCard'
import { Link } from 'react-router-dom'
import constants from '../../../../Util/constants'

const GeneralTab: PublicClient.AcademicClasses.Tab = ({ displayedAcademicClass, lang, settings, currentLang }) => {
    return (
        <div className="fesbAcademicClassTab" style={constants.isOnMobile ? { paddingTop: '10px' } : undefined}>
            <h2>{lang.ACADEMIC_CLASSES.enrollmentConditions}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        displayedAcademicClass?.phaseTwo?.data[('enrollmentConditions_' + currentLang) as 'enrollmentConditions_hr'] ||
                        `<div>${lang.noData}</div>`,
                }}
            ></div>
            <h2>{lang.ACADEMIC_CLASSES.goals}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html: displayedAcademicClass![('goals_' + currentLang) as 'goals_hr'] || `<div>${lang.noData}</div>`,
                }}
            ></div>
            <h2>{lang.ACADEMIC_CLASSES.resultOfStudy}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        displayedAcademicClass?.phaseTwo?.data[('resultOfStudy_' + currentLang) as 'resultOfStudy_hr'] ||
                        `<div>${lang.noData}</div>`,
                }}
            ></div>
            <h2>{lang.ACADEMIC_CLASSES.headProfessors}</h2>
            <div className="fesbAcademicClassesHeadProfessorsList">
                {displayedAcademicClass!.headProfessors
                    ? displayedAcademicClass?.headProfessors.map((headProfessor, index) => (
                          <Link
                              key={'HP-' + index}
                              to={'/educators/employees/user-' + headProfessor.mainEntityId}
                              style={{ textDecoration: 'none', color: 'var(--colorPrimaryLight)', fontWeight: 700 }}
                          >
                              {headProfessor.title ? `${headProfessor.title} ` : ''}
                              {`${headProfessor.lastName} ${headProfessor.firstName}`}
                          </Link>
                      ))
                    : null}
            </div>
            <h2>{lang.ACADEMIC_CLASSES.classesAndLecturers}</h2>
            {displayedAcademicClass?.classExecutionPlans ? (
                displayedAcademicClass.classExecutionPlans.map((executionPlan) => (
                    <MethodOfExecutionCard
                        key={'MECK-' + executionPlan.mainEntityId}
                        executionPlan={executionPlan}
                        lang={lang}
                        settings={settings}
                        currentLang={currentLang}
                    />
                ))
            ) : (
                <div>{lang.noData}</div>
            )}
        </div>
    )
}

export default GeneralTab
