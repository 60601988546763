import report, { REPORT_PRIORITIES } from './Reporter/report'

export const createPostRequest = async <T>(url: string, params?: any): Promise<T> => {
    console.log('%c Sending post request...', 'background: #1fa67f; color: white', url, params)
    let response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params || {}),
    })
    reportResponseErrors(response)
    let responseJson = await response.json()
    console.log('%c Post response:', 'background: #1fa67f; color: white', url, responseJson)
    return responseJson
}
export const createPutRequest = async <T>(url: string, params?: any): Promise<T> => {
    console.log('Sending put request...', url, params)
    let response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params || {}),
    })
    reportResponseErrors(response)
    let responseJson = await response.json()
    console.log('Put response:', url, responseJson)
    return responseJson
}
export const createGetRequest = async <T>(url: string, params?: any): Promise<T> => {
    console.log('%c Sending get request...', 'background: #d57524; color: white', url, params)
    let response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    reportResponseErrors(response)
    let responseJson = await response.json()
    console.log('%c Get response:', 'background: #d57524; color: white', url, responseJson)
    return responseJson
}
export const createDeleteRequest = async <T>(url: string, params?: any): Promise<T> => {
    console.log('%c Sending delete request...', 'background: #a51b4b; color: white', url, params)
    let response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params || {}),
    })
    reportResponseErrors(response)
    let responseJson = await response.json()
    console.log('%c Delete response:', 'background: #a51b4b; color: white', url, responseJson)
    return responseJson
}

const reportResponseErrors = (response: Response) => {
    if (response.status >= 400 && response.status < 500)
        report({
            reportToCentral: { priority: REPORT_PRIORITIES.HIGH },
            message: JSON.stringify({ status: response.status, url: response.url }),
        })
}
