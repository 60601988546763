import React from 'react'
import useCourseListElement from './useCourseListElement'
import { Link } from 'react-router-dom'

const CourseListElement: PublicClient.Courses.CourseListElement = (props) => {
    const service = useCourseListElement()
    return (
        <div
            style={{
                height: service.elementHeight ? service.elementHeight : 'auto',
                overflow: 'hidden',
                opacity: service.elementHeight ? 1 : 0,
            }}
            onMouseEnter={service.onMouseEnter}
            onMouseLeave={service.onMouseLeave}
            key={props.listItem.key}
            className="fesbCourseListElementContainer"
            ref={service.ref}
        >
            <div className="fesbListElementMeasurer">
                <Link
                    key={props.listItem.key}
                    className="fesbCourseListElement"
                    to={props.currentPath + '/course-' + props.listItem.mainEntityId}
                    onClick={window.innerWidth < 1000 ? props.onClick : undefined}
                >
                    <div
                        style={{ display: 'grid', gridTemplateColumns: '50px 1fr', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <div style={{ marginRight: '10px' }}>{props.listItem.code}</div>
                        <div>{props.listItem[('name_' + props.currentLang) as 'name_hr']}</div>
                    </div>
                    {props.listItem.specializations.length ? (
                        <i
                            style={{ marginLeft: '5px' }}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                //this is used for mobile, clicking will still trigger the on hover eevent and show the dropdown but will not trigger the Link onClick prop
                            }}
                            className="fas fa-chevron-down"
                        ></i>
                    ) : null}
                </Link>
                {props.listItem.specializations.map((specialization, index) => {
                    return (
                        <Link
                            to={props.currentPath + '/course-' + props.listItem.mainEntityId + '-' + specialization.mainEntityId}
                            className="fesbCourseListElement specialization"
                            key={'ks-' + props.listItem.key + '-' + index}
                            onClick={window.innerWidth < 1000 ? props.onClick : undefined}
                        >
                            <div style={{ marginRight: '10px' }}>{specialization.code}</div>
                            <div>{specialization[('name_' + props.currentLang) as 'name_hr']}</div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default CourseListElement
