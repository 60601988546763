import { useState, useEffect, useRef } from 'react'
import { useSettings } from '../../Util/Settings/useSettings'
import EducatorsApi from './EducatorsApi'
import { useLocation } from 'react-router-dom'
import getCanonicalPath from '../../Util/getCanonicalPath'
import useNavigationTree from '../DynamicHeader/NavigationTree'

const useEducators: PublicClient.Educators.Service = (props) => {
    const [dataLoaded, dataLoaded_set] = useState<boolean>(false)
    const [dataLoading, dataLoading_set] = useState<boolean>(false)
    const [displayedEducator, displayedEducator_set] = useState<PublicClient.Educators.API.GetById.Data | undefined>()
    const [list, list_set] = useState<PublicClient.Educators.Service.ListMember[]>([])
    const [locallyFilteredList, locallyFilteredList_set] = useState<PublicClient.Educators.Service.ListMember[]>([])
    const settings = useSettings()
    const [filters, filters_set] = useState<PublicClient.Educators.API.GetEducatorList.Filters | undefined>()
    const [internalFilter, internalFilter_set] = useState<string | undefined>()
    const [activeTab, activeTab_set] = useState<'cv' | 'science' | 'classes'>('cv')
    const location = useLocation()
    const [offsetTop, offsetTop_set] = useState<boolean>(false)
    const [offsetBottom, offsetBottom_set] = useState<boolean>(false)
    const navigationTree = useNavigationTree()
    const infoRef = useRef<HTMLDivElement>()

    useEffect(() => {
        let attempts = 0
        const canonicalPath = getCanonicalPath(location.pathname, navigationTree)
        let currentTimeout: any
        const waitForRender = () => {
            if (infoRef.current) {
                if (infoRef.current.scrollHeight > infoRef.current.clientHeight !== offsetBottom) offsetBottom_set(!offsetBottom)
                if (infoRef.current.scrollTop && !offsetTop) offsetTop_set(true)
                else if (!infoRef.current.scrollTop && offsetTop) offsetTop_set(false)
            } else if (attempts < 20) {
                attempts++
                currentTimeout = setTimeout(waitForRender, 500)
            }
        }
        //if an option is chosen, wait for render then check if the content is scrolled and if it can be scrolled
        if (canonicalPath !== location.pathname) waitForRender()
        return () => currentTimeout && clearTimeout(currentTimeout)
    }, [activeTab, location.pathname])

    const onScroll = (e: any) => {
        if ((e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) === offsetBottom) offsetBottom_set(!offsetBottom)
        if (e.target.scrollTop && !offsetTop) offsetTop_set(true)
        else if (!e.target.scrollTop && offsetTop) offsetTop_set(false)
    }

    const getList = async (filters?: PublicClient.Educators.API.GetEducatorList.Filters) => {
        const fetchedList = await EducatorsApi.getUserList(filters)
        if (fetchedList) {
            list_set(
                fetchedList.map((listMember) => {
                    return { ...listMember, key: 'k' + listMember.mainEntityId }
                })
            )
        }
    }
    const loadUser = async () => {
        const locationParams = location.pathname.split('/')
        const userParameter = locationParams[locationParams.length - 1]
        if (userParameter.slice(0, 4) === 'user') {
            const userId = Number(userParameter.slice(5))
            dataLoading_set(true)
            const fetchedData = await EducatorsApi.getById(userId)
            if (fetchedData) {
                displayedEducator_set(fetchedData)
                dataLoaded_set(true)
                dataLoading_set(false)
            } else {
                dataLoaded_set(false)
                dataLoading_set(false)
            }
        }
    }

    useEffect(() => {
        loadUser()
    }, [location.pathname])
    useEffect(() => {
        if (props.navigationOptions[1] !== undefined && settings.settings) {
            let newFilters: PublicClient.Educators.API.GetEducatorList.Filters = {}
            switch (props.navigationOptions[1]) {
                case 0:
                    newFilters.isEmployee = 1
                    break
                case 1:
                    newFilters.isEmployee = 2
                    break
                default:
                    throw new Error('Invalid choice for user type')
            }
            filters_set(newFilters)
        }
    }, [props.navigationOptions[1], settings.settings])
    useEffect(() => {
        if (filters) {
            getList(filters)
        }
    }, [filters])
    useEffect(() => {
        if (list) {
            if (internalFilter) {
                const lowerCaseFilter = internalFilter.toLocaleLowerCase()
                const newList = list.filter((listItem) => {
                    const lowerCaseFirstName = listItem.firstName.toLocaleLowerCase()
                    const lowerCaseLastName = listItem.lastName.toLocaleLowerCase()
                    return (
                        (lowerCaseFirstName + ' ' + lowerCaseLastName).includes(lowerCaseFilter) ||
                        (lowerCaseLastName + ' ' + lowerCaseFirstName).includes(lowerCaseFilter) ||
                        (lowerCaseLastName + ', ' + lowerCaseFirstName).includes(lowerCaseFilter)
                    )
                })
                locallyFilteredList_set(newList)
            } else locallyFilteredList_set(list)
        }
    }, [internalFilter, list])

    return {
        settingsLoaded: settings.settings ? true : false,
        listLoaded: locallyFilteredList ? true : false,
        dataLoaded,
        dataLoading,
        locallyFilteredList,
        internalFilter,
        internalFilter_set,
        displayedEducator,
        settings: settings.settings,
        activeTab,
        activeTab_set,
        offsetBottom,
        offsetTop,
        infoRef,
        onScroll,
    }
}

export default useEducators
