import { getChosenOptionsFromPath, traverseTree } from '../Components/DynamicHeader/useDynamicHeader'
import useNavigationTree from '../Components/DynamicHeader/NavigationTree'

export default (pathname: string, navigationTree: PublicClient.DynamicHeader.NavigationTree) => {
    //get current path ignoring any parameters
    const navigationData = getChosenOptionsFromPath(pathname, navigationTree)
    let canonicalPath = '/'
    if (navigationData && navigationData.chosenOptions && navigationData.chosenOptions.length) {
        if (navigationData.chosenOptions.length > 1) {
            const traversedTree = traverseTree(navigationTree, navigationData.chosenOptions.slice(0, -1))
            if (traversedTree) {
                canonicalPath = (traversedTree as any)[navigationData.chosenOptions[navigationData.chosenOptions.length - 1]].linkTo
            }
        } else canonicalPath = navigationTree[navigationData.chosenOptions[0]].linkTo
    }
    return canonicalPath
}
