if (!process.env.REACT_APP_SERVER_URL) throw new Error('server url not defined in env file')
const biggerSide = Math.max(window.innerWidth, window.innerHeight)
const smallerSide = Math.min(window.innerWidth, window.innerHeight)
const isOnMobile = smallerSide < 500 || biggerSide < 1000
const dynamicHeaderTopOffset = isOnMobile ? smallerSide / 45 : 30
const mainHeaderHeight = isOnMobile ? smallerSide / 15 : 40
const dynamicHeaderHeight = isOnMobile ? smallerSide / 18 : 40
export default {
    splashCardSize: isOnMobile ? (smallerSide - dynamicHeaderTopOffset - mainHeaderHeight - dynamicHeaderHeight * 1.1) * 0.4 : 260,
    splashCardAreaOccupiedFactor: 1.1,
    mainHeaderHeight,
    dynamicHeaderTopOffset,
    dynamicHeaderHeight,
    dynamicHeaderLeftOffset: 50,
    serverUrl: process.env.REACT_APP_SERVER_URL,
    isOnMobile,
    logoHeight: (window.innerHeight / 100) * 8 + 20,
}
