import React, { useState, useEffect } from 'react'
import CoursesApi from './CoursesApi'
import { useSettings } from '../../Util/Settings/useSettings'
import checkSpecialization from '../../Util/checkSpecialization'
import { useLocation } from 'react-router-dom'
import { useLocale } from '../../Util/Locale/useLocale'

const useCourses: PublicClient.Courses.Service = ({ navigationOptions }) => {
    const [list, list_set] = useState<PublicClient.Courses.Service.ListMember[]>([])
    const { currentLang } = useLocale<Locale.Keys>()
    const [filteredList, filteredList_set] = useState<PublicClient.Courses.Service.ListMember[]>([])
    const [loadingData, loadingData_set] = useState<boolean>(false)
    const [listLoadError, listLoadError_set] = useState<boolean>(false)
    const [loadingCourse, loadingCourse_set] = useState<boolean>(false)
    const [courseLoadError, courseLoadError_set] = useState<boolean>(false)
    const [isMinimized, isMinimized_set] = useState<boolean>(false)
    const [activeYear, activeYear_set] = useState<number>(1)
    const [loadingSettings, loadingSettings_set] = useState<boolean>(true)
    const [courseSpecializationId, courseSpecializationId_set] = useState<number | undefined>()
    const [methodsOfExecution, methodsOfExecution_set] = useState<PublicClient.Settings.ExecutionMethod[]>()
    const [academicClassesToShowBySemester, academicClassesToShowBySemester_set] = useState<{
        [semester: number]: PublicClient.Courses.API.GetCourseById.CourseClass[]
    }>({})
    const [courseFilters, courseFilters_set] = useState<PublicClient.Courses.API.GetFilteredCourseList.Filters>()
    const [courseInternalFilter, courseInternalFilter_set] = useState<string | undefined>()
    const settings = useSettings()
    const [currentCourse, currentCourse_set] = useState<PublicClient.Courses.API.GetCourseById.Data>()
    const location = useLocation()
    const nextYear = () => {
        if (activeYear < Math.round(Object.keys(academicClassesToShowBySemester).length / 2)) {
            activeYear_set((activeYear) => activeYear + 1)
        }
    }
    const previousYear = () => {
        if (activeYear > 1) {
            activeYear_set((activeYear) => activeYear - 1)
        }
    }
    const loadCourse = async () => {
        const locationParams = location.pathname.split('/')
        const courseParameter = locationParams[locationParams.length - 1]
        if (courseParameter.slice(0, 6) === 'course') {
            let courseId: number
            let specializationId: number | undefined = undefined
            const splitParam = courseParameter.slice(7).split('-')
            if (splitParam[0] !== '') {
                courseId = Number(splitParam[0])
                if (splitParam[1]) specializationId = Number(splitParam[1])
                loadingCourse_set(true)
                const fetchedData = await CoursesApi.getCourseById(courseId)
                if (fetchedData) {
                    currentCourse_set(fetchedData)
                    loadingCourse_set(false)
                    courseLoadError_set(false)
                    courseSpecializationId_set(specializationId)
                } else {
                    courseLoadError_set(true)
                    loadingCourse_set(false)
                }
            }
        }
    }

    useEffect(() => {
        loadCourse()
    }, [location.pathname])
    // const loadCourse = async (courseId: number, specializationId?: number) => {
    //     loadingCourse_set(true)
    //     const loadedCourse = await CoursesApi.getCourseById(courseId)
    //     courseSpecializationId_set(specializationId)
    //     if (loadedCourse) await currentCourse_set(loadedCourse)
    //     else await courseLoadError_set(false)
    //     loadingCourse_set(false)
    // }
    useEffect(() => {
        if (list) {
            if (courseInternalFilter) {
                const newList = list.filter((listItem) =>
                    listItem[('name_' + currentLang) as 'name_hr'].toLocaleLowerCase().includes(courseInternalFilter.toLocaleLowerCase())
                )
                filteredList_set(newList)
            } else filteredList_set(list)
        }
    }, [courseInternalFilter, list])
    useEffect(() => {
        if (settings.settings) {
            methodsOfExecution_set(settings.settings.executionMethods)
            loadingSettings_set(false)
        }
    }, [settings.settings])
    useEffect(() => {
        if (settings.settings && navigationOptions[1] !== undefined) {
            const newFilters: PublicClient.Courses.API.GetFilteredCourseList.Filters = {}
            if (navigationOptions[1]) newFilters.type = navigationOptions[1]
            if (navigationOptions[2]) newFilters.level = navigationOptions[2]

            courseFilters_set(newFilters)
        }
    }, [navigationOptions[1], navigationOptions[2], settings.settings])
    useEffect(() => {
        //sort classes into semesters
        const newSort: { [key: number]: PublicClient.Courses.API.GetCourseById.CourseClass[] } = {}
        if (currentCourse) {
            for (const courseClass of currentCourse.data.courseClasses) {
                if (
                    checkSpecialization(
                        courseClass.data.specialization ? { id: courseClass.data.specialization.id } : null,
                        courseSpecializationId
                    )
                ) {
                    if (!newSort[courseClass.data.semester]) newSort[courseClass.data.semester] = []
                    newSort[courseClass.data.semester].push({
                        id: courseClass.id,
                        data: {
                            ...courseClass.data,
                            courseClassExecutionPlans: courseClass.data.courseClassExecutionPlans || [],
                        },
                    })
                }
            }
        }
        academicClassesToShowBySemester_set(newSort)
    }, [currentCourse, courseSpecializationId])
    const loadData = async (filters?: PublicClient.Courses.API.GetFilteredCourseList.Filters) => {
        loadingData_set(true)
        const newList = await CoursesApi.getFilteredCourseList(filters)
        if (newList)
            await list_set(
                newList.map((listMember) => {
                    return { ...listMember, key: 'k' + listMember.mainEntityId }
                })
            )
        else listLoadError_set(true)
        loadingData_set(false)
    }
    useEffect(() => {
        if (courseFilters) loadData(courseFilters)
    }, [courseFilters])
    return {
        filteredList,
        loadingData,
        listLoadError,
        courseLoadError,
        currentCourse,
        loadingCourse,
        methodsOfExecution,
        loadingSettings,
        courseSpecializationId,
        academicClassesToShowBySemester,
        courseInternalFilter,
        courseInternalFilter_set,
        isMinimized,
        isMinimized_set,
        activeYear,
        nextYear,
        previousYear,
    }
}

export default useCourses
