import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocale } from '../../Util/Locale/useLocale'
import LanguagePicker from './LanguagePicker/LanguagePicker'
import constants from '../../Util/constants'

const StaticHeader = () => {
    const { lang } = useLocale<Locale.Keys>()
    const [menuHeight, menuHeight_set] = useState<number>(0)
    const [currentHeight, currentHeight_set] = useState<number>(0)
    useEffect(() => {
        const dropdownElement = document.querySelector('.fesbStaticHeaderLinkDropdown')
        if (dropdownElement) {
            const linkHeight = dropdownElement.querySelector('a')!.getBoundingClientRect().height
            menuHeight_set(dropdownElement.children.length * linkHeight)
        }
    }, [])
    return (
        <nav className="fesbStaticHeader" style={{ height: constants.mainHeaderHeight }}>
            {constants.isOnMobile && (
                <i
                    className="fas fa-bars fesbStaticMenuHamburger"
                    onClick={() => currentHeight_set((oldHeight) => (oldHeight ? 0 : menuHeight))}
                ></i>
            )}
            <div
                style={constants.isOnMobile ? { height: currentHeight } : undefined}
                className={constants.isOnMobile ? 'fesbStaticHeaderLinkDropdown' : 'fesbStaticHeaderLinkContainer'}
            >
                <Link to={{ pathname: 'https://www.fesb.unist.hr/' }} target="_blank" rel="noreferrer noopener">
                    Web
                </Link>
                <Link to={{ pathname: 'https://mail.fesb.hr/' }} target="_blank" rel="noreferrer noopener">
                    Mail
                </Link>
                <Link to={{ pathname: 'https://fesb.sharepoint.com/' }} target="_blank" rel="noreferrer noopener">
                    Sharepoint
                </Link>
                <Link to={{ pathname: 'https://elearning.fesb.unist.hr/' }} target="_blank" rel="noreferrer noopener">
                    eLearning
                </Link>
                <Link to={{ pathname: 'https://raspored.fesb.unist.hr/' }} target="_blank" rel="noreferrer noopener">
                    {lang.STATIC_HEADER.schedule}
                </Link>
                <Link
                    to={'/'}
                    style={{ background: 'var(--colorPrimary)', borderBottom: '3px solid var(--colorPrimary', fontWeight: 'bold' }}
                >
                    {lang.STATIC_HEADER.program}
                </Link>
                <Link to={{ pathname: 'https://e-indeks.fesb.unist.hr/' }} target="_blank" rel="noreferrer noopener">
                    eIndex
                </Link>
                <Link to={{ pathname: 'https://azureforeducation.microsoft.com/devtools' }} target="_blank" rel="noreferrer noopener">
                    DreamSpark
                </Link>
                <Link to={{ pathname: 'https://upisi.fesb.unist.hr/' }} target="_blank" rel="noreferrer noopener">
                    {lang.STATIC_HEADER.enrollment}
                </Link>
                <Link to={{ pathname: 'https://radovi.fesb.unist.hr/' }} target="_blank" rel="noreferrer noopener">
                    {lang.STATIC_HEADER.graduateThesis}
                </Link>
            </div>
            <LanguagePicker />
        </nav>
    )
}

export default StaticHeader
