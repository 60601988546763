import React from 'react'
export function Spinner(props: { label?: string; style?: React.CSSProperties }) {
    return (
        <div
            style={{
                background: '#CCCCCC00',
                opacity: 1,
                zIndex: 1000,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...props.style,
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 0, height: 0, flexDirection: 'column' }}>
                <i className="fas fa-5x fa-circle-notch fa-spin"></i>
                {props.label ? <h2 style={{ textAlign: 'center', width: '300px' }}>{props.label}</h2> : null}
            </div>
        </div>
    )
}
