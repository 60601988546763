import React, { useReducer, useContext } from 'react'

let StateManager: React.Context<StateManager.StoreContext>

export { StateManager }

export function StateProvider({ children }: { children: React.ReactNode }) {
    const [store, dispatch] = useReducer(stateProviderReducer, {})

    function updateStore<S, A>(key: string, reducer: (state: S, action: A) => S, action: A): void {
        let subState: S = store ? store[key] : null
        let tempState = reducer(subState, action)
        dispatch({ type: 'SET_PAYLOAD', key: key, payload: tempState })
    }
    function getFromStore<S>(key: string): S {
        let subState: S = store ? store[key] : null
        return subState
    }
    let context = {
        store: store,
        updateStore: updateStore,
        getFromStore,
    }
    if (!StateManager) StateManager = React.createContext<StateManager.StoreContext>(context)
    const Provider = StateManager.Provider
    return React.createElement(Provider, { value: context, children: children })
}
function stateProviderReducer(state: StateManager.Store, action: StateManager.Action) {
    switch (action.type) {
        case 'SET_PAYLOAD':
            if (action.key) {
                return {
                    ...state,
                    ...{ [action.key]: action.payload },
                }
            } else {
                return state
            }
    }
}

export function useStore(): StateManager.StoreContext {
    const store = useContext(StateManager)
    return store
}
