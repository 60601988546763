import React from 'react'
import { Link } from 'react-router-dom'

const AcademicClassListElement: PublicClient.AcademicClasses.ListElement = (props) => {
    return (
        <Link
            className="fesbAcademicClassListElement"
            key={props.listItem.key}
            onClick={props.onClick}
            to={props.currentPath + '/courseClass-' + props.listItem.courseClassMainEntityId}
        >
            <div className="row classRow">
                <div>{props.listItem[('name_' + props.currentLang) as 'name_hr']}</div>
                <div>{props.listItem.code}</div>
            </div>
            <div className="row courseRow">
                <div>{(props.listItem as any)['courseName_' + props.currentLang]}</div>
                <div>{props.listItem.courseCode}</div>
            </div>
            <div>{`${props.listItem.semester}. ${props.lang.semester}`}</div>
        </Link>
    )
}

export default AcademicClassListElement
