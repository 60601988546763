import { createPostRequest, createGetRequest } from '../../Util/connection'
import constants from '../../Util/constants'

const getFilteredCourseList: PublicClient.Courses.API.GetFilteredCourseList = async (filters) => {
    try {
        const response = await createPostRequest<PublicClient.Courses.API.GetFilteredCourseList.Response>(
            constants.serverUrl + '/courses/getFilteredList',
            {
                filters,
            }
        )
        if (response.status === 'ERROR') {
            console.log('===error getting course list===\n')
            return null
        }
        return response.data
    } catch (error) {
        console.log('===error getting course list===\n', error)
        return null
    }
}

const getCourseById: PublicClient.Courses.API.GetCourseById = async (id) => {
    try {
        const response = await createGetRequest<PublicClient.Courses.API.GetCourseById.Response>(
            constants.serverUrl + '/courses/detailedGetByIdForPublicClient:' + id
        )
        if (response.status === 'ERROR') {
            console.log('===error getting course with id' + id + '===\n')
            return null
        }
        return response.data
    } catch (error) {
        console.log('===error getting course with id' + id + '===\n', error)
        return null
    }
}

export default {
    getFilteredCourseList,
    getCourseById,
}
