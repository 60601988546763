import getOptionType from './getOptionType'

export default (
    settings: PublicClient.Settings.Setting[] | undefined,
    optionId: number | undefined,
    errorText: string,
    currentLang: 'en' | 'hr'
) => {
    const optionType = getOptionType(settings, optionId)
    let title = ''
    if (optionType) {
        title = currentLang === 'en' ? optionType.title_en : optionType.title_hr
    }
    return title
}
