import React, { useState, useEffect } from 'react'
import { useLocale } from '../../../Util/Locale/useLocale'
import hr from '../../../Util/Locale/hr'
import en from '../../../Util/Locale/en'

const LanguagePicker = () => {
    const language = useLocale<Locale.Keys>()
    const [showDropDown, showDropDown_set] = useState<boolean>(false)
    const [dropdownIsMousedOver, dropdownIsMousedOver_set] = useState<boolean>(false)
    const [buttonIsMousedOver, buttonIsMousedOver_set] = useState<boolean>(false)
    const switchLanguage = (newLanguage: 'hr' | 'en') => {
        let lang: Locale.State<Locale.Keys> = {
            langKey: newLanguage,
            hr: hr(),
            en: en(),
        }
        language.setLanguages(lang)
    }
    useEffect(() => {
        if (!buttonIsMousedOver && !dropdownIsMousedOver && showDropDown) showDropDown_set(false)
    }, [dropdownIsMousedOver, buttonIsMousedOver, showDropDown])
    return (
        <div
            className="fesbLanguagePickerContainer"
            onClick={() => showDropDown_set((current) => !current)}
            onMouseEnter={() => buttonIsMousedOver_set(true)}
            onMouseLeave={() => buttonIsMousedOver_set(false)}
        >
            <i className="fas fa-globe"></i>
            <ul
                className={'fesbLanguagePickerDropDown' + (showDropDown ? ' open' : ' closed')}
                onMouseEnter={() => dropdownIsMousedOver_set(true)}
                onMouseLeave={() => dropdownIsMousedOver_set(false)}
            >
                <li className="fesbLanguagePickerOption" onClick={() => switchLanguage('hr')}>
                    {'Hrvatski'}
                </li>
                <li className="fesbLanguagePickerOption" onClick={() => switchLanguage('en')}>
                    {'English'}
                </li>
            </ul>
        </div>
    )
}

export default LanguagePicker
