import React from 'react'
import { StateProvider } from './Util/StateManager'
import AppBody from './Components/AppBody/AppBody'
import { BrowserRouter } from 'react-router-dom'

function App() {
    return (
        <StateProvider>
            <BrowserRouter>
                <AppBody />
            </BrowserRouter>
        </StateProvider>
    )
}

export default App
