import constants from './constants'

export default (elementId: string) => {
    const HEADER_ELEMENT_MARGIN = constants.dynamicHeaderHeight * 0.1
    const currentHeaderBarElements = document.querySelectorAll('.fesbHeaderBarOption')
    //get upper right corner of current header bar
    let TOP: number
    let LEFT: number
    if (currentHeaderBarElements.length) {
        const rect = currentHeaderBarElements[currentHeaderBarElements.length - 1].getBoundingClientRect()
        const sizeRect = (currentHeaderBarElements[currentHeaderBarElements.length - 1]
            .firstChild as HTMLDivElement).getBoundingClientRect()
        TOP = rect.top - sizeRect.height / 2
        LEFT = rect.left + sizeRect.width / 2
    } else {
        TOP = constants.dynamicHeaderTopOffset + constants.mainHeaderHeight
        LEFT = constants.dynamicHeaderLeftOffset
    }

    const element = document.querySelector('#' + elementId)
    try {
        if (element) {
            //create invisible element to calculate required width
            const testElement = element.cloneNode(true)
            ;(testElement as HTMLElement).classList.add('fesbHeaderBarOptionTest', 'fesbHiddenSplashCard')
            document.body.appendChild(testElement)
            const testElementContent = (testElement as HTMLElement).querySelector('.fesbSplashCardContent')
            if (!testElementContent) throw new Error()
            const { height, width } = testElementContent?.getBoundingClientRect()
            document.body.removeChild(testElement)
            const elementContent = element.querySelector('.fesbSplashCardContent')
            if (!elementContent) throw new Error()
            const elementContentStyle = (elementContent as HTMLDivElement).style
            const elementStyle = (element as HTMLDListElement).style
            //animate size
            elementContentStyle.minHeight = height + 'px'
            elementContentStyle.minWidth = width + 'px'
            //animate position
            elementStyle.left = LEFT + width / 2 + HEADER_ELEMENT_MARGIN + 'px'
            elementStyle.top = TOP + height / 2 + 'px'
        }
    } catch (error) {
        console.log('===error===', error)
        return null
    }
    // const head
    // const {left, top} = element.getBoundingClientRect()
    // const origin = {top: top+height/2, left: left+width/2};
}
