import React, { useState, useEffect } from 'react'

const ScrollArrow = (props: { direction: 'up' | 'down'; style?: React.CSSProperties }) => {
    const FADE_TIME = 1000
    const [opacity, opacity_set] = useState<number>(1)
    useEffect(() => {
        const interval = setInterval(() => {
            opacity_set((current) => {
                return current ? 0 : 1
            })
        }, FADE_TIME)
        return () => clearInterval(interval)
    }, [])
    const style: React.CSSProperties = {
        ...props.style,
        transition: 'opacity ' + FADE_TIME / 1000 + 's',
        opacity,
    }
    return props.direction === 'down' ? (
        <i className="fas fa-angle-double-down" style={style}></i>
    ) : (
        <i className="fas fa-angle-double-up" style={style}></i>
    )
}

export default ScrollArrow
