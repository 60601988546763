export const getFromLocalStorage = <T>(key: string): T => {
    let data: any = localStorage.getItem(key)
    data = data ? JSON.parse(data) : null
    return data
}

export const updateLocalStorage = <T>(key: string, data?: T): T | undefined => {
    localStorage.setItem(key, data ? JSON.stringify(data) : '')
    return data
}

export const switchLanguages = (langkey: 'hr' | 'en') => {
    const currentUrl = window.location.href
    let newUrl
    const searchJSON = getJsonFromUrl(currentUrl)
    const oldSuffix = searchJSON.lang ? 'lang=' + searchJSON.lang : ''
    const newSuffix = langkey === 'hr' ? '' : 'lang=' + langkey
    if (newSuffix === oldSuffix) return null
    if (searchJSON.lang) {
        if (Object.keys(searchJSON).length === 1) {
            newUrl = currentUrl.replace('?' + oldSuffix, langkey === 'hr' ? '' : '?' + newSuffix)
        } else if (Object.keys(searchJSON)[0] === 'lang') newUrl = currentUrl.replace(oldSuffix + '&', '')
        else newUrl = currentUrl.replace('&' + oldSuffix, langkey === 'hr' ? '' : '&' + newSuffix)
    } else if (searchJSON.length) {
        newUrl = currentUrl + '&' + newSuffix
    } else newUrl = currentUrl + '?' + newSuffix
    return newUrl as 'hr' | 'en'
}
export const createLangURLFromBaseURL = (url: string, language: 'hr' | 'en') => {
    if (language === 'hr') return url
    return url + '?lang=' + language
}
export function getJsonFromUrl(url?: string) {
    if (!url) url = window.location.href
    var question = url.indexOf('?')
    var hash = url.indexOf('#')
    if (hash == -1 && question == -1) return {}
    if (hash == -1) hash = url.length
    var query = question == -1 || hash == question + 1 ? url.substring(hash) : url.substring(question + 1, hash)
    var result: { [key: string]: string[] | string } = {}
    query.split('&').forEach(function (part) {
        if (!part) return
        part = part.split('+').join(' ') // replace every + with space, regexp-free version
        var eq = part.indexOf('=')
        var key = eq > -1 ? part.substr(0, eq) : part
        var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : ''
        var from = key.indexOf('[')
        if (from == -1) result[decodeURIComponent(key)] = val
        else {
            var to = key.indexOf(']', from)
            var index = decodeURIComponent(key.substring(from + 1, to))
            key = decodeURIComponent(key.substring(0, from))
            if (!result[key]) result[key] = []
            if (!index || !Number(index)) (result[key] as string[]).push(val)
            else (result[key] as string[])[Number(index)] = val
        }
    })
    return result
}
