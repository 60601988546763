import React from 'react'

const ContentTab: PublicClient.AcademicClasses.Tab = ({ displayedAcademicClass, lang, currentLang }) => {
    return (
        <div className="fesbAcademicClassTab">
            <h2>{lang.ACADEMIC_CLASSES.content}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        displayedAcademicClass?.phaseTwo?.data[('content_' + currentLang) as 'content_hr'] || `<div>${lang.noData}</div>`,
                }}
            ></div>
            <h2>{lang.ACADEMIC_CLASSES.suggestedLiterature}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html: displayedAcademicClass?.phaseTwo?.data.suggestedLiterature || `<div>${lang.noData}</div>`,
                }}
            ></div>
            <h2>{lang.ACADEMIC_CLASSES.extraLiterature}</h2>
            <div
                dangerouslySetInnerHTML={{ __html: displayedAcademicClass?.phaseTwo?.data.extraLiterature || `<div>${lang.noData}</div>` }}
            ></div>
        </div>
    )
}

export default ContentTab
