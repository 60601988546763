export default class Color{
    hue = 0
    saturation = 0
    value = 100
    alpha = 1

    constructor(props?:Color.Class) {
        if(props && props.hue)this.hue = props.hue
        if(props && props.saturation)this.saturation = props.saturation
        if(props && props.value)this.value = props.value
        if(props && props.alpha) this.alpha = props.alpha

        this.format = this.format.bind(this)
    }

    hueColor(newHue?:number):Color{
        let hueColor = new Color()
        hueColor.hue = newHue || this.hue
        hueColor.saturation = 100
        hueColor.value = 100
        hueColor.alpha = 1
        return hueColor
    }
    blackOrWhite():Color{
        let bwColor = new Color()
        bwColor.value = (this.value >= 50 && this.saturation < 50) || this.alpha < .5 ? 0 : 100
        return bwColor
    }

    format(format:Color.Types){
        if(format.toLowerCase() === "rgba"){
            let rgb = Color.HSVtoRGB({
                h:this.hue,
                s:this.saturation,
                v:this.value
            })
            return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${this.alpha.toFixed(2)})`
        }
        else if(format.toLowerCase() === "rgb"){
            let rgb = Color.HSVtoRGB({
                h:this.hue,
                s:this.saturation,
                v:this.value
            })
            return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
        }
        else if(format.toLowerCase() === "hex"){
            let rgb = Color.HSVtoRGB({
                h:this.hue,
                s:this.saturation,
                v:this.value
            })
            return Color.RGBtoHEX(rgb)
        }
        else{
            return `hsla(${this.hue}, ${this.saturation}, ${this.value}, ${this.alpha})`
        }
    }
    static parse(colorString:string):Color|null{
        if(!colorString)
            return new Color()

        let rgb, hsv, alpha, color = new Color(), numberValues = colorString.match(/(\d+\.)?\d+/g)
 
        if(/^#([A-Fa-f0-9]{6})$/g.test(colorString)){ 
            //HEX
            rgb = Color.HEXtoRGB(colorString)
            hsv = Color.RGBtoHSV(rgb)
        }
        else if(numberValues && numberValues.length === 4 && /^rgba\(([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\s*(0(\.\d+)?|1(\.0+)?)\)$/.test(colorString)){
            //RGBA
            rgb = {r:parseInt(numberValues[0]),g:parseInt(numberValues[1]),b:parseInt(numberValues[2])}
            hsv = Color.RGBtoHSV(rgb)
            alpha = parseFloat(numberValues[3])
        }
        else if(numberValues && numberValues.length === 3 && /^rgb\(([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\)$/.test(colorString)){
            //RGB
            rgb = {r:parseInt(numberValues[0]),g:parseInt(numberValues[1]),b:parseInt(numberValues[2])}
            hsv = Color.RGBtoHSV(rgb)
            alpha = 1
        }
        else if(numberValues && numberValues.length === 3 && /^hsv\(([012]?[0-9]?[0-9]|3[0-5][0-9]|360),\s*([0]?[0-9]?[0-9]|100)%?,\s*([0]?[0-9]?[0-9]|100)%?\)$/.test(colorString)){
            //HSV
            hsv = {h:parseInt(numberValues[0]),s:parseInt(numberValues[1]),v:parseInt(numberValues[2])}
        }
        else
        {
            return null
        }
        color.hue = hsv.h
        color.saturation = hsv.s
        color.value = hsv.v
        color.alpha = alpha || 1
        return color
    }
   
    static HSVtoRGB({h, s, v}:Color.HSV):Color.RGB {
        h = h/360
        s = s/100
        v = v/100
        let rgb:Color.RGB

        let i = Math.floor(h * 6)
        let f = h * 6 - i
        let p = v * (1 - s)
        let q = v * (1 - f * s)
        let t = v * (1 - (1 - f) * s)

        switch (i % 6) {
            case 0: 
                rgb = {r:v, g:t, b:p}
                break
            case 1:
                rgb = {r:q, g:v, b:p}
                break
            case 2:
                rgb = {r:p, g:v, b:t}
                break
            case 3: 
                rgb = {r:p, g:q, b:v}
                break
            case 4: 
                rgb = {r:t, g:p, b:v}
                break
            case 5: 
                rgb = {r:v, g:p, b:q}
                break
            default: 
                rgb = {r:0, g:0, b:0}
                break
        }

        return {r:Math.round(rgb.r * 255), g:Math.round(rgb.g * 255), b:Math.round(rgb.b * 255)}
    }
    static RGBtoHSV ({r, g, b}:Color.RGB):Color.HSV {
        r = r / 255
        g = g / 255
        b = b / 255
        var rr:number, gg:number, bb:number, h:any, s:number,
            v:number = Math.max(r, g, b),
            diff:number = v - Math.min(r, g, b),
            diffc = function(c:number){
                return (v - c) / 6 / diff + 1 / 2
            }

        if (diff === 0) {
            h = s = 0
        } else {
            s = diff / v;
            rr = diffc(r)
            gg = diffc(g)
            bb = diffc(b)

            if (r === v) {
                h = bb - gg;
            }else if (g === v) {
                h = (1 / 3) + rr - bb;
            }else if (b === v) {
                h = (2 / 3) + gg - rr;
            }
            if (h < 0) {
                h += 1
            }else if (h > 1) {
                h -= 1
            }
        }
        return {
            h: Math.round(h * 360),
            s: Math.round(s * 100),
            v: Math.round(v * 100)
        }
    }
    static RGBtoHEX(rgb:Color.RGB):string{
        let {r, g, b}:any = rgb
        return "#" +
            ("0" + parseInt(r,10).toString(16)).slice(-2) +
            ("0" + parseInt(g,10).toString(16)).slice(-2) +
            ("0" + parseInt(b,10).toString(16)).slice(-2)
    }
    static HEXtoRGB(hex:string):Color.RGB{
        if(hex.length === 4){
            hex = hex[1]+hex[1]+hex[2]+hex[2]+hex[3]+hex[3]
        }
        return {r:parseInt(hex.substring(1,3),16), g:parseInt(hex.substring(3,5),16), b:parseInt(hex.substring(5,7),16)}

    }
}

export const colorPalette = [
    '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', 
    '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', 
    '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF5722', 
    '#795548', '#607D8B', '#ffffff', '#9E9E9E', '#333333'
]