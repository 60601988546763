import React from 'react'
import SpinWhileLoading from '../../Common/SpinnWhileLoading/SpinnWhileLoading'

const ScienceTab: PublicClient.Educators.Tab = ({ displayedEducator, lang }) => {
    return (
        <SpinWhileLoading dataLoaded={displayedEducator ? true : false}>
            <main className="fesbEducatorContent">
                <h2 style={{ marginTop: '0' }}>{lang.EDUCATORS.publishedPapers}</h2>
                <div>{lang.EDUCATORS.PUBLISHED_PAPERS_TEXT}</div>
                {displayedEducator!.bibirbId ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://bib.irb.hr/lista-radova?autor=' + displayedEducator!.bibirbId}
                        className="bibirLink"
                    >
                        {'https://bib.irb.hr/lista-radova?autor=' + displayedEducator!.bibirbId}
                    </a>
                ) : (
                    lang.noData
                )}
            </main>
        </SpinWhileLoading>
    )
}

export default ScienceTab
