import React from 'react'
import SpinWhileLoading from '../../Common/SpinnWhileLoading/SpinnWhileLoading'
import { Link } from 'react-router-dom'

const ClassesTab: PublicClient.Educators.ClassesTab = ({ displayedEducator, lang, currentLang, settings }) => {
    const participationByCourseType: { [courseLevel: number]: UserServices.GetUserByIdForPublicClient.ParticipationData[] } = {}
    if (displayedEducator) {
        for (const participationData of displayedEducator.participationData) {
            if (!participationByCourseType[participationData.courseType]) participationByCourseType[participationData.courseType] = []
            participationByCourseType[participationData.courseType].push(participationData)
        }
    }
    return (
        <SpinWhileLoading dataLoaded={displayedEducator ? true : false}>
            {settings ? (
                <main className="fesbEducatorContent">
                    {Object.entries(participationByCourseType).map(([courseType, participationData]) => {
                        const foundCourseType = settings.courseTypes.find(
                            (currentCourseType) => currentCourseType.mainEntityId === Number(courseType)
                        )
                        if (foundCourseType) {
                            return (
                                <div key={'k' + courseType}>
                                    <h2 style={{ marginTop: '0' }} className="fesbEducatorParticipationTitle">
                                        {currentLang === 'en' ? foundCourseType.title_en : foundCourseType.title_hr}
                                    </h2>
                                    <ul style={{ padding: '0' }}>
                                        {participationData.map((entry, index) => {
                                            let methodOfExecutionName
                                            if (entry.typeOfParticipation === 'headProfessor')
                                                methodOfExecutionName = lang.EDUCATORS.headProfessor
                                            else {
                                                const foundMethodOfExecution = settings.executionMethods.find(
                                                    (method) => method.mainEntityId === entry.typeOfParticipation
                                                )
                                                if (foundMethodOfExecution) {
                                                    methodOfExecutionName =
                                                        currentLang === 'en'
                                                            ? foundMethodOfExecution.title_en
                                                            : foundMethodOfExecution.title_hr
                                                } else methodOfExecutionName = lang.error
                                            }
                                            return (
                                                <li
                                                    key={'k2-' + index}
                                                    className="fesbEducatorParticipationRow"
                                                    style={index % 2 ? { backgroundColor: 'var(--colorWhiteDarkBackground)' } : undefined}
                                                >
                                                    <Link
                                                        to={
                                                            '/classes/all/courseClass-' +
                                                            entry.courseClassMainEntityId +
                                                            (entry.specializationMainEntityId ? '-' + entry.specializationMainEntityId : '')
                                                        }
                                                    >{`${
                                                        (entry as any)['academicClassName_' + currentLang] + ' - ' + entry.academicClassCode
                                                    }`}</Link>
                                                    <div>
                                                        <Link
                                                            to={`/courses/${settings.courseTypes
                                                                .find((CT) => CT.mainEntityId === entry.courseType)
                                                                ?.title_en.toLocaleLowerCase()}/${settings.courseLevels
                                                                .find((CT) => CT.mainEntityId === entry.courseLevel)
                                                                ?.title_en.toLocaleLowerCase()}/course-${entry.courseMainEntityId}`}
                                                        >
                                                            {' '}
                                                            {`${(entry as any)['courseName_' + currentLang]} - ${entry.courseCode}`}
                                                        </Link>
                                                        {` ${
                                                            entry.specializationMainEntityId
                                                                ? (currentLang === 'en'
                                                                      ? ` (${lang.specialization}: ${entry.specializationTitleEn}`
                                                                      : ` (${lang.specialization}: ${entry.specializationTitleHr}`) +
                                                                  ' ' +
                                                                  entry.specializationCode +
                                                                  ')'
                                                                : ''
                                                        }`}
                                                    </div>
                                                    <div>{`${methodOfExecutionName}`}</div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        } else return <div>{lang.error}</div>
                    })}
                </main>
            ) : (
                <div>{lang.error}</div>
            )}
        </SpinWhileLoading>
    )
}

export default ClassesTab
