import React from 'react'
import convertBufferToImageSrc from '../../../Util/convertBufferToImageSrc'
import getUserType from '../../../Util/getOptionType'
import getOptionTitle from '../../../Util/getOptionTitle'
import { Link, useLocation } from 'react-router-dom'

const EducatorListElement: PublicClient.Educators.EducatorListElement = (props) => {
    //get the name of the user type associated with this user
    const currentUserType = getOptionTitle(props.settings?.userTypes, props.listItem.type, props.lang.error, props.currentLang)
    return (
        <Link
            className="fesbEducatorListElement"
            to={props.currentPath + '/user-' + props.listItem.mainEntityId}
            onClick={window.innerWidth < 1000 ? props.onClick : undefined}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                    <div className="fesbUserFrame">
                        {props.listItem.smallPictureLink ? (
                            <img src={convertBufferToImageSrc(props.listItem.smallPictureLink.data)}></img>
                        ) : (
                            <i className="fas fa-user" />
                        )}
                    </div>
                    <div
                        className={'fesbEducatorListElementGridSegment'}
                        style={{
                            gridTemplateRows:
                                'auto' + (props.listItem.title ? ' auto' : '') + (props.listItem.departmentAbbreviation ? ' auto' : ''),
                        }}
                    >
                        {props.listItem.title ? (
                            <div style={{ textTransform: 'lowercase', marginRight: '0.5rem', fontSize: '0.8rem', alignSelf: 'flex-end' }}>
                                {props.listItem.title}
                            </div>
                        ) : null}
                        {`${props.listItem.lastName} ${props.listItem.firstName}`}
                        {props.listItem.departmentAbbreviation ? (
                            <div
                                style={{
                                    textTransform: 'uppercase',
                                    marginRight: '0.5rem',
                                    fontSize: '0.8rem',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                {props.listItem.departmentAbbreviation}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div style={{ fontSize: '0.7rem' }}>{currentUserType}</div>
            </div>
        </Link>
    )
}

export default EducatorListElement
