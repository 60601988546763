import React from 'react'
import { useLocale } from '../../../Util/Locale/useLocale'
import { Spinner } from '../Spinner/Spinner'

const SpinWhileLoading: PublicClient.SpinWhileLoading = (props) => {
    const { lang } = useLocale<Locale.Keys>()
    return props.dataLoaded ? props.children : <Spinner style={{ color: 'var(--colorPrimary)' }} label={lang.loading + '...'} />
}

export default SpinWhileLoading
