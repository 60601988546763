import React from 'react'
import useAcademicClasses from './useAcademicClasses'
import SpinWhileLoading from '../Common/SpinnWhileLoading/SpinnWhileLoading'
import SearchBox from '../Common/SearchBox/SearchBox'
import AcademicClassListElement from './AcademicClassListElement/AcademicClassListElement'
import { useLocale } from '../../Util/Locale/useLocale'
import GeneralTab from './Tabs/GeneralTab/GeneralTab'
import getNumberSuffix from '../../Util/getNumberSuffix'
import getOptionTitle from '../../Util/getOptionTitle'
import ContentTab from './Tabs/Content/ContentTab'
import ExecutionTab from './Tabs/Execution/ExecutionTab'
import ClassUnitsTab from './Tabs/ClassUnits/ClassUnitsTab'
import constants from '../../Util/constants'
import ScrollArrow from '../Common/ScrollArrow/ScrollArrow'
import { Link } from 'react-router-dom'

const AcademicClasses: PublicClient.AcademicClasses = (props) => {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    const service = useAcademicClasses(props)
    const courseLevelOfDisplayedClass =
        service.settings && service.displayedAcademicClass
            ? service.settings.courseLevels.find((cl) => cl.mainEntityId === service.displayedAcademicClass!.courseLevel)
            : undefined
    const courseTypeOfDisplayedClass =
        service.settings && service.displayedAcademicClass
            ? service.settings.courseTypes.find((ct) => ct.mainEntityId === service.displayedAcademicClass!.courseType)
            : undefined
    let academicClassType = getOptionTitle(
        service.settings?.academicClassTypes,
        service.displayedAcademicClass?.type,
        lang.error,
        currentLang
    )
    let academicClassLevel = getOptionTitle(
        service.settings?.academicClassLevels,
        service.displayedAcademicClass?.level,
        lang.error,
        currentLang
    )
    let gradingMethod = getOptionTitle(
        service.settings?.gradingMethods,
        service.displayedAcademicClass?.phaseTwo?.data.gradingMethod,
        lang.error,
        currentLang
    )
    const courseType = getOptionTitle(service.settings?.courseTypes, service.displayedAcademicClass?.courseType, lang.error, currentLang)
    const courseLevel = getOptionTitle(service.settings?.courseLevels, service.displayedAcademicClass?.courseLevel, lang.error, currentLang)
    const FixedSegment = service.dataLoaded ? (
        <div className="fesbAcademicClassesFixedSegment">
            <div
                className="fesbAcademicClassesFixedInfoBar"
                style={{
                    fontSize: '1.4rem',
                    textTransform: 'uppercase',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    paddingRight: '15%',
                    marginBottom: '1rem',
                    color: 'var(--colorWhiteBackground)',
                    textAlign: 'start',
                    background: 'linear-gradient(270deg, var(--colorWhiteBackground) 0%, var(--colorPrimaryDark) 60%)',
                }}
            >
                <div style={{ fontSize: '0.9rem' }}>{service.displayedAcademicClass!.code}</div>
                <div>{service.displayedAcademicClass![('name_' + currentLang) as 'name_hr']}</div>
            </div>
            <div className="fesbAcademicClassesFixedInfoBar">{`${service.displayedAcademicClass!.forAcademicYear}/${String(
                service.displayedAcademicClass!.forAcademicYear + 1
            ).slice(2)}`}</div>
            <div className="fesbAcademicClassesFixedInfoBar" style={{ fontSize: '1.4rem', color: '#cc0000' }}>{`${
                service.displayedAcademicClass!.ects
            } ECTS`}</div>
            <div
                className="fesbAcademicClassesFixedInfoBar"
                style={{
                    fontSize: '1.2rem',
                    textTransform: 'uppercase',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    marginTop: '1rem',
                }}
            >
                <div style={{ fontSize: '0.85rem' }}>{service.displayedAcademicClass!.courseCode}</div>
                {service.settings && service.displayedAcademicClass && courseTypeOfDisplayedClass && (
                    <Link
                        style={{ color: 'var(--colorPrimaryDark)' }}
                        to={
                            '/courses/' +
                            courseTypeOfDisplayedClass['title_en'].toLocaleLowerCase() +
                            (courseLevelOfDisplayedClass
                                ? (('/' + courseLevelOfDisplayedClass['title_en'].toLocaleLowerCase()) as string)
                                : '') +
                            '/course-' +
                            service.displayedAcademicClass?.courseId
                        }
                    >
                        {service.displayedAcademicClass![('courseName_' + currentLang) as 'courseName_hr']}
                    </Link>
                )}
                {service.displayedAcademicClass &&
                    service.displayedAcademicClass.specializationId &&
                    service.settings &&
                    courseTypeOfDisplayedClass && (
                        <Link
                            style={{ color: 'var(--colorPrimary)', fontSize: '0.85rem' }}
                            to={
                                '/courses/' +
                                courseTypeOfDisplayedClass['title_en'].toLocaleLowerCase() +
                                (courseLevelOfDisplayedClass
                                    ? (('/' + courseLevelOfDisplayedClass['title_en'].toLocaleLowerCase()) as string)
                                    : '') +
                                '/course-' +
                                service.displayedAcademicClass?.courseId +
                                '-' +
                                service.displayedAcademicClass?.specializationId
                            }
                        >
                            {service.displayedAcademicClass.specializationCode +
                                ' ' +
                                service.displayedAcademicClass[currentLang === 'hr' ? 'specializationTitleHr' : 'specializationTitleEn']}
                        </Link>
                    )}
            </div>
            <div className="fesbAcademicClassesFixedInfoBar">{`${courseType} ${courseLevel} ${lang.course}`}</div>
            <div className="fesbAcademicClassesFixedInfoBar" style={{ marginBottom: '1rem' }}>{`${
                service.displayedAcademicClass?.semester
            }${currentLang === 'en' ? getNumberSuffix(service.displayedAcademicClass!.semester) : '.'} ${lang.semester}`}</div>
            <div className="fesbAcademicClassesFixedInfoBar">{academicClassType}</div>
            <div className="fesbAcademicClassesFixedInfoBar">{academicClassLevel}</div>
            <div
                className="fesbAcademicClassesFixedInfoBar"
                style={{
                    fontSize: '1.2rem',
                    textTransform: 'uppercase',
                    flexDirection: 'column',
                    height: '45px',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                }}
            >
                <div style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{lang.ACADEMIC_CLASSES.gradingMethod}</div>
                {gradingMethod}
            </div>
        </div>
    ) : null
    return (
        <div className="fesbEducatorsView fesbView" style={service.dataLoading || service.dataLoaded ? undefined : { width: '350px' }}>
            <SpinWhileLoading dataLoaded={service.listLoaded}>
                <div className="fesbSearchBoxAndContent">
                    {(service.dataLoading || service.dataLoaded) && service.offsetTop && constants.isOnMobile && (
                        <ScrollArrow
                            direction="up"
                            style={{
                                position: 'absolute',
                                top: '55px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'var(--colorPrimary)',
                                fontSize: '2rem',
                                zIndex: 20,
                            }}
                        />
                    )}
                    {(service.dataLoading || service.dataLoaded) && service.offsetBottom && constants.isOnMobile && (
                        <ScrollArrow
                            direction="down"
                            style={{
                                position: 'absolute',
                                bottom: '10px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'var(--colorPrimary)',
                                fontSize: '2rem',
                                zIndex: 20,
                            }}
                        />
                    )}
                    <SearchBox<PublicClient.AcademicClasses.Service.ListMember>
                        filters={[]}
                        style={
                            service.dataLoading || service.dataLoaded
                                ? constants.isOnMobile
                                    ? undefined
                                    : { borderTopRightRadius: '0', borderBottomRightRadius: '0' }
                                : undefined
                        }
                        internalFilter={service.internalFilter}
                        setInternalFilter={service.internalFilter_set}
                        listMemberRenderFunction={AcademicClassListElement}
                        data={service.locallyFilteredList}
                    />
                    <SpinWhileLoading dataLoaded={!service.dataLoading}>
                        {service.dataLoaded ? (
                            <div className="fesbAcademicClassesContent" onScroll={service.onScroll}>
                                <div className="fesbAcademicClassesTabs">
                                    <div className="fesbAcademicClassesTab" onClick={() => service.activeTab_set('general')}>
                                        {lang.ACADEMIC_CLASSES.general}
                                    </div>
                                    <div className="fesbAcademicClassesTab" onClick={() => service.activeTab_set('content')}>
                                        {lang.ACADEMIC_CLASSES.content}
                                    </div>
                                    <div className="fesbAcademicClassesTab" onClick={() => service.activeTab_set('execution')}>
                                        {lang.ACADEMIC_CLASSES.execution}
                                    </div>
                                    <div className="fesbAcademicClassesTab" onClick={() => service.activeTab_set('classUnits')}>
                                        {lang.ACADEMIC_CLASSES.classUnits}
                                    </div>
                                </div>
                                {(service.activeTab === 'general' || !constants.isOnMobile) && FixedSegment}
                                {service.activeTab === 'general' && (
                                    <GeneralTab
                                        displayedAcademicClass={service.displayedAcademicClass}
                                        lang={lang}
                                        settings={service.settings as PublicClient.Settings.SettingsData}
                                        currentLang={currentLang}
                                    />
                                )}
                                {service.activeTab === 'content' && (
                                    <ContentTab
                                        displayedAcademicClass={service.displayedAcademicClass}
                                        lang={lang}
                                        settings={service.settings as PublicClient.Settings.SettingsData}
                                        currentLang={currentLang}
                                    />
                                )}
                                {service.activeTab === 'execution' && (
                                    <ExecutionTab
                                        displayedAcademicClass={service.displayedAcademicClass}
                                        lang={lang}
                                        settings={service.settings as PublicClient.Settings.SettingsData}
                                        currentLang={currentLang}
                                    />
                                )}
                                {service.activeTab === 'classUnits' && (
                                    <ClassUnitsTab
                                        displayedAcademicClass={service.displayedAcademicClass}
                                        lang={lang}
                                        settings={service.settings as PublicClient.Settings.SettingsData}
                                        currentLang={currentLang}
                                    />
                                )}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </SpinWhileLoading>
                </div>
            </SpinWhileLoading>
        </div>
    )
}

export default AcademicClasses
