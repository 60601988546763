import React from 'react'
import './ClassExecutionPlanRow.scss'
import { useLocale } from '../../../Util/Locale/useLocale'
import { Link } from 'react-router-dom'

const ClassExecutionPlanRow: PublicClient.Courses.ExecutionPlan.Row = (props) => {
    const { currentLang } = useLocale<Locale.Keys>()
    let formattedMethods: { id: number; abbreviation: string; hours: number | null }[] = []
    if (!props.data) return null
    if (props.methods.length) {
        //make sure there aren't too many categories which would cause formatting problems
        for (let i = 0; i < 8; i++)
            if (!props.methods[i]) formattedMethods.push({ id: -2, abbreviation: '', hours: null })
            else if (i < 7) {
                formattedMethods.push({ ...props.methods[i], hours: 0, id: props.methods[i].mainEntityId })
            } else {
                formattedMethods.push({ id: -1, abbreviation: '...', hours: 0 })
            }
        //calculate category hours
        props.data.data.academicClass.data.classExecutionPlans.forEach((cep) => {
            const relevantCategory = formattedMethods.find((method) => method.id === cep.data.methodOfExecution)
            if (relevantCategory) {
                ;(relevantCategory.hours as number) += cep.data.hoursPerWeek * cep.data.lengthInWeeks
            } else (formattedMethods.find((method) => method.id === -1)!.hours as number) += cep.data.hoursPerWeek * cep.data.lengthInWeeks
        })
    }
    return (
        <div className="FesbClassExecutionPlanRow">
            <div className="smallBox">{props.data.data.academicClass.data.code}</div>
            <Link to={'/classes/all/courseClass-' + props.data.id}>
                {props.data.data.academicClass.data[('name_' + currentLang) as 'name_hr']}
            </Link>
            <div>
                {props.data.data.headProfessors.map((headProfessor, index) => {
                    return (
                        <Link
                            to={'/educators/' + (headProfessor.data.isEmployee ? 'employees' : 'associates') + '/user-' + headProfessor.id}
                            key={headProfessor.id}
                        >
                            {index ? ', ' : ''}
                            {`${headProfessor.data.firstName.slice(0, 1)}. ${headProfessor.data.lastName}`}
                        </Link>
                    )
                })}
            </div>
            {formattedMethods.map((method, index) =>
                method.hours === null ? (
                    <div key={'fesbAbCat-' + index}></div>
                ) : (
                    <div className="smallBox" key={'fesbAbCat-' + index}>
                        {method.hours}
                    </div>
                )
            )}
            <div className="smallBox">{props.data.data.academicClass.data.ects}</div>
        </div>
    )
}

export default ClassExecutionPlanRow
