import React, { useState, useEffect } from 'react'
import { useLocale } from '../../Util/Locale/useLocale'
import { getJsonFromUrl } from '../../Util/ClientUtil'
import hr from '../../Util/Locale/hr'
import en from '../../Util/Locale/en'
import DynamicHeader from '../DynamicHeader/DynamicHeader'
import Courses from '../Courses/Courses'
import StaticHeader from '../StaticHeader/StaticHeader'
import { Switch, Route, Link, useLocation } from 'react-router-dom'
import routes from '../../Util/routes'
import { useSettings } from '../../Util/Settings/useSettings'
import Educators from '../Educators/Educators'
import AcademicClasses from '../AcademicClasses/AcademicClasses'
import Logo from '../../Resources/FesbLogo.gif'
import PageNotFound from '../Common/404/PageNotFound'
import { getChosenOptionsFromPath } from '../DynamicHeader/useDynamicHeader'
import EUImage from '../../Resources/eu.png'
import HKOImage from '../../Resources/hko.png'
import { Helmet } from 'react-helmet'
import useNavigationTree from '../DynamicHeader/NavigationTree'

const AppBody = () => {
    const { setLanguages, lang } = useLocale<Locale.Keys>()
    const settings = useSettings()
    const [localeLoaded, localeLoaded_set] = useState<boolean>(false)
    const location = useLocation()
    const [currentUrl, currentUrl_set] = useState<string | undefined>()
    const URLLang = getJsonFromUrl(window.location.href).lang
    const navigationTree = useNavigationTree()
    const parseResult = getChosenOptionsFromPath(location.pathname, navigationTree)
    useEffect(() => {
        settings.loadSettings()
        if (window.innerWidth < 600)
            document.querySelector('html')!.style.fontSize = Math.min(window.innerHeight, window.innerWidth) / 30 + 'px'
    }, [])
    useEffect(() => {
        let currentLang: 'en' | 'hr' = URLLang === 'en' ? 'en' : 'hr'
        let lang: Locale.State<Locale.Keys> = {
            langKey: currentLang,
            hr: hr(),
            en: en(),
        }
        setLanguages(lang)
        localeLoaded_set(true)
    }, [URLLang])

    return localeLoaded ? (
        <div className="fesbPublicApp">
            <Helmet>
                <title>{lang.pageTitle}</title>
            </Helmet>
            <StaticHeader />
            <DynamicHeader currentUrl_set={currentUrl_set} chosenOptions={parseResult ? parseResult.chosenOptions : []} />
            <Switch>
                {/**
                 * navigationDone is true when the end of the navigation tree has been reached and it is time to render content
                 * according to whatever the leaf node contains
                 *
                 * location.pathname is not always equal to the currentUrl variable. This check is done to prevent invalid
                 * data being passed to a new component before the new url can be parsed. Because url parsing is asynchronous,
                 * the navigationOptions variable will not have the correct value on the first render of a new component . The DynamicHeader
                 * component detects every url change then parses the new url and, when its done updates both the navigationOptions and
                 * the currentUrl variables. When location.pathname is the same as currentUrl we know that the url parsing is done and we
                 * can then render the component.
                 */}
                {parseResult?.navigationDone && (
                    <Route path={routes.courses} render={() => <Courses navigationOptions={parseResult.chosenOptions} />} />
                )}
                {!parseResult?.navigationDone && <Route path={routes.courses} render={() => null} />}
                {parseResult?.navigationDone && (
                    <Route path={routes.educators} render={() => <Educators navigationOptions={parseResult.chosenOptions} />} />
                )}
                {!parseResult?.navigationDone && <Route path={routes.educators} render={() => null} />}
                {parseResult?.navigationDone && (
                    <Route path={routes.classes} render={() => <AcademicClasses navigationOptions={parseResult.chosenOptions} />} />
                )}
                {!parseResult?.navigationDone && <Route path={routes.classes} render={() => null} />}
                <Route path={'/'} exact render={() => null} />
                {location.pathname === currentUrl && <Route path={'*'} component={PageNotFound} />}
            </Switch>
            <div className="FesbLogoRow">
                <div className={'startLogo' + (parseResult && parseResult.chosenOptions.length ? ' hidden' : '')}>
                    <img src={EUImage}></img>
                    <div className="logoContainer">
                        <img src={HKOImage}></img>
                        <span>HKO-ELE</span>
                    </div>
                </div>
                <Link
                    className={parseResult && parseResult.chosenOptions.length ? 'fesbLogo' : 'fesbLogoStart'}
                    to={{ pathname: 'https://www.fesb.unist.hr/' }}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <img src={Logo}></img>
                </Link>
            </div>
        </div>
    ) : null
}

export default AppBody
