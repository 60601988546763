import React from 'react'
import { Link } from 'react-router-dom'

const SplashCard: PublicClient.SplashCard = (props) => {
    return (
        <Link
            className={'fesbSplashCard' + (props.class ? ' ' + props.class : '')}
            style={{
                top: props.position.top,
                left: props.position.left,
            }}
            to={props.to}
            id={props.id}
        >
            <div
                style={{
                    backgroundImage: props.backgroundImageUrl ? `url(${props.backgroundImageUrl})` : undefined,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    minHeight: props.position.height,
                    minWidth: props.position.width,
                    ...props.style,
                }}
                onClick={props.onClick}
                className="fesbSplashCardContent"
            >
                <div className="fesbSplashCardOverlay">
                    <h1 className="fesbSplashCardTitle">{props.title}</h1>
                </div>
            </div>
        </Link>
    )
}

export default SplashCard
