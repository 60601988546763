import React from 'react'
import useCourses from './useCourses'
import { useLocale } from '../../Util/Locale/useLocale'
import SpinWhileLoading from '../Common/SpinnWhileLoading/SpinnWhileLoading'
import SearchBox from '../Common/SearchBox/SearchBox'
import CourseListElement from './CourseListElement/CourseListElement'
import ClassExecutionPlanHeaderRow from './ExecutionPlanDisplay/ClassExecutionPlanHeaderRow'
import ClassExecutionPlanRow from './ExecutionPlanDisplay/ClassExecutionPlanRow'
import constants from '../../Util/constants'

const Courses = (props: { navigationOptions: number[] }) => {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    const service = useCourses(props)
    return (
        <div
            className="fesbCoursesView fesbView"
            style={!constants.isOnMobile ? (service.loadingCourse || service.currentCourse ? undefined : { width: '350px' }) : undefined}
        >
            <SpinWhileLoading dataLoaded={!service.loadingData}>
                <div className="fesbSearchBoxAndContent">
                    <SearchBox<PublicClient.Courses.Service.ListMember>
                        filters={[]}
                        style={
                            (service.loadingCourse || service.currentCourse) && window.innerWidth > 1000
                                ? { borderTopRightRadius: '0', borderBottomRightRadius: '0' }
                                : undefined
                        }
                        internalFilter={service.courseInternalFilter}
                        setInternalFilter={service.courseInternalFilter_set}
                        listMemberRenderFunction={CourseListElement}
                        data={service.filteredList}
                    />
                    <SpinWhileLoading dataLoaded={!service.loadingCourse && !service.loadingSettings}>
                        {service.methodsOfExecution ? (
                            <div className="fesbInfoPanel">
                                <div className="fesbCourseHeader">
                                    <i
                                        className="fas fa-chevron-left"
                                        onClick={service.previousYear}
                                        style={service.activeYear > 1 ? undefined : { color: 'grey', cursor: 'unset' }}
                                    ></i>
                                    {service.activeYear}
                                    {currentLang === 'en'
                                        ? service.activeYear === 1
                                            ? 'st'
                                            : service.activeYear === 2
                                            ? 'nd'
                                            : service.activeYear === 3
                                            ? 'rd'
                                            : 'th'
                                        : '.'}
                                    {' ' + lang.year.toLocaleLowerCase()}
                                    <i
                                        className="fas fa-chevron-right"
                                        onClick={service.nextYear}
                                        style={
                                            service.activeYear < Math.round(Object.keys(service.academicClassesToShowBySemester).length / 2)
                                                ? undefined
                                                : { color: 'grey', cursor: 'unset' }
                                        }
                                    ></i>
                                </div>
                                {Object.entries(service.academicClassesToShowBySemester)
                                    .slice((service.activeYear - 1) * 2, service.activeYear * 2)
                                    .map(([semester, courseClasses], index) => {
                                        return (
                                            <div key={'CEPHRK-' + index}>
                                                <ClassExecutionPlanHeaderRow
                                                    semester={Number(semester)}
                                                    methods={service.methodsOfExecution as any}
                                                />
                                                {courseClasses.map((courseClass, index) => {
                                                    return (
                                                        <ClassExecutionPlanRow
                                                            key={'CEPRK-' + index}
                                                            data={courseClass}
                                                            methods={service.methodsOfExecution!}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                            </div>
                        ) : (
                            <div>{lang.error}</div>
                        )}
                    </SpinWhileLoading>
                </div>
            </SpinWhileLoading>
        </div>
    )
}

export default Courses
