import React from 'react'
import getOptionType from '../../../../Util/getOptionType'
import Color from '../../../../Util/color/Color'

const ClassUnitsTab: PublicClient.AcademicClasses.Tab = ({ displayedAcademicClass, lang, settings, currentLang }) => {
    return (
        <div className="fesbAcademicClassTab">
            {displayedAcademicClass?.classExecutionPlans.map((executionPlan) => {
                const relevantMethodEntity = getOptionType(settings?.executionMethods, executionPlan.methodOfExecution)
                let borderColor = relevantMethodEntity ? (relevantMethodEntity as any).color : 'var(--colorPrimary)'
                const title = currentLang === 'en' ? relevantMethodEntity?.title_en : relevantMethodEntity?.title_hr
                const sortedClassUnits = executionPlan.classUnits.sort((a, b) => (a.index > b.index ? 1 : -1))
                return (
                    <div key={'K-' + executionPlan.mainEntityId}>
                        <h3 className="fesbClassUnitTitle">{title}</h3>
                        <ol style={{ paddingLeft: 0, borderLeft: '5px solid ' + borderColor }}>
                            {sortedClassUnits.map((classUnit) => {
                                return (
                                    <div
                                        className="fesbClassUnitsListItem"
                                        key={'CUK-' + classUnit.mainEntityId}
                                        style={{
                                            backgroundColor:
                                                classUnit.index % 2 === 1
                                                    ? 'var(--colorWhiteDarkBackground)'
                                                    : 'var(--colorWhiteBackground)',
                                        }}
                                    >
                                        <div>{`${classUnit.index}.`}</div>
                                        <div style={{ padding: '5px' }}>
                                            {classUnit![('description_' + currentLang) as 'description_hr']}
                                        </div>
                                        <div>{`${classUnit.hours} ${(classUnit.hours === 1
                                            ? lang.hour
                                            : classUnit.hours < 5
                                            ? lang.hours
                                            : lang.hours2
                                        ).toLocaleLowerCase()}`}</div>
                                    </div>
                                )
                            })}
                        </ol>
                    </div>
                )
            })}
        </div>
    )
}

export default ClassUnitsTab
