import { createPostRequest, createGetRequest } from '../../Util/connection'
import constants from '../../Util/constants'

const getList: PublicClient.AcademicClasses.API.GetList = async (filters) => {
    try {
        const response = await createPostRequest<PublicClient.AcademicClasses.API.GetList.Response>(
            constants.serverUrl + '/academicClasses/listForPublicClient',
            {
                filters,
            }
        )
        if (response.status === 'ERROR') {
            console.log('===error getting class list===\n')
            return null
        }
        return response.data
    } catch (error) {
        console.log('===error getting class list===\n', error)
        return null
    }
}

const getById: PublicClient.AcademicClasses.API.GetById = async (id) => {
    try {
        const response = await createGetRequest<PublicClient.AcademicClasses.API.GetById.Response>(
            constants.serverUrl + '/academicClasses/byCourseClassIdForPublicClient:' + id
        )
        if (response.status === 'ERROR') {
            console.log('===error getting class with id' + id + '===\n')
            return null
        }
        return response.data
    } catch (error) {
        console.log('===error getting class with id' + id + '===\n', error)
        return null
    }
}

export default {
    getList,
    getById,
}
