import { useContext } from 'react'
import { StateManager } from '../StateManager'
import { updateLocalStorage } from '../ClientUtil'
import { createGetRequest } from '../connection'
import constants from '../constants'

export function useSettings(): PublicClient.Settings.Export {
    const storeName: string = 'settings'
    const { updateStore, store } = useContext(StateManager)
    let settings: PublicClient.Settings.SettingsData = store.settings

    const loadSettings = async () => {
        const loadedSettings = await fetchSettings()
        if (!loadedSettings) {
            console.error('No settings')
            return null
        }
        const loadedMethods = loadedSettings.find((category) => category.mainEntityId === 5)
        if (!loadedMethods) {
            console.error('No methods')
            return null
        }
        const courseTypes = loadedSettings.find((category) => category.mainEntityId === 4)
        if (!courseTypes) {
            console.error('No courseTypes')
            return null
        }
        const courseLevels = loadedSettings.find((category) => category.mainEntityId === 3)
        if (!courseLevels) {
            console.error('No course levels')
            return null
        }
        const userTypes = loadedSettings.find((category) => category.mainEntityId === 1)
        if (!userTypes) {
            console.error('No user types')
            return null
        }
        const academicClassLevels = loadedSettings.find((category) => category.mainEntityId === 7)
        if (!academicClassLevels) {
            console.error('No academic class levels')
            return null
        }
        const academicClassTypes = loadedSettings.find((category) => category.mainEntityId === 2)
        if (!academicClassTypes) {
            console.error('No academic class types')
            return null
        }
        const gradingMethods = loadedSettings.find((category) => category.mainEntityId === 6)
        if (!gradingMethods) {
            console.error('No grading methods')
            return null
        }
        const formattedSettings: PublicClient.Settings.SettingsData = {
            executionMethods: (loadedMethods.options as unknown) as PublicClient.Settings.ExecutionMethod[],
            courseTypes: courseTypes.options,
            courseLevels: courseLevels.options,
            userTypes: userTypes.options,
            academicClassLevels: academicClassLevels.options,
            academicClassTypes: academicClassTypes.options,
            gradingMethods: gradingMethods.options,
        }
        updateStore<PublicClient.Settings.SettingsData, PublicClient.Settings.Action>(storeName, localeReducer, {
            type: 'load',
            data: formattedSettings,
        })
    }
    return {
        settings,
        loadSettings,
    }
}
function localeReducer(
    state: PublicClient.Settings.SettingsData,
    action: PublicClient.Settings.Action
): PublicClient.Settings.SettingsData {
    let { type, ...newState } = action
    switch (action.type) {
        case 'load':
            return action.data
        default:
            return state
    }
}

const fetchSettings = async () => {
    try {
        const response = await createGetRequest<PublicClient.Settings.Response>(constants.serverUrl + '/setupOptions/getAllForPublicClient')
        if (response.status === 'ERROR') {
            console.log('===error fetching settings===\n')
            return null
        }
        return response.data
    } catch (error) {
        console.log('===error fetching settings===\n', error)
        return null
    }
}
