import React from 'react'
import SpinWhileLoading from '../Common/SpinnWhileLoading/SpinnWhileLoading'
import useEducators from './useEducators'
import SearchBox from '../Common/SearchBox/SearchBox'
import EducatorListElement from './EducatorListElement/EducatorListElement'
import { useLocale } from '../../Util/Locale/useLocale'
import convertBufferToImageSrc from '../../Util/convertBufferToImageSrc'
import userPlaceholder from '../../Resources/userPlaceholder.png'
import CVTab from './Tabs/CVTab'
import ScienceTab from './Tabs/ScienceTab'
import ClassesTab from './Tabs/ClassesTab'
import getOptionTitle from '../../Util/getOptionTitle'
import constants from '../../Util/constants'
import ScrollArrow from '../Common/ScrollArrow/ScrollArrow'

const Educators: PublicClient.Educators = (props) => {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    const service = useEducators(props)
    const currentUserType = getOptionTitle(
        service.settings?.userTypes,
        service.displayedEducator ? service.displayedEducator.type : undefined,
        lang.error,
        currentLang
    )
    return (
        <div className="fesbEducatorsView fesbView" style={service.dataLoading || service.dataLoaded ? undefined : { width: '350px' }}>
            <SpinWhileLoading dataLoaded={service.listLoaded}>
                <div className="fesbSearchBoxAndContent">
                    {service.offsetTop && constants.isOnMobile && (
                        <ScrollArrow
                            direction="up"
                            style={{
                                position: 'absolute',
                                top: '55px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'var(--colorPrimary)',
                                fontSize: '2rem',
                            }}
                        />
                    )}
                    {service.offsetBottom && constants.isOnMobile && (
                        <ScrollArrow
                            direction="down"
                            style={{
                                position: 'absolute',
                                bottom: '10px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'var(--colorPrimary)',
                                fontSize: '2rem',
                            }}
                        />
                    )}
                    <SearchBox<PublicClient.Educators.Service.ListMember>
                        filters={[]}
                        style={
                            service.dataLoading || service.dataLoaded
                                ? constants.isOnMobile
                                    ? undefined
                                    : { borderTopRightRadius: '0', borderBottomRightRadius: '0' }
                                : undefined
                        }
                        internalFilter={service.internalFilter}
                        setInternalFilter={service.internalFilter_set}
                        listMemberRenderFunction={EducatorListElement}
                        data={service.locallyFilteredList}
                    />
                    <SpinWhileLoading dataLoaded={!service.dataLoading}>
                        {service.displayedEducator ? (
                            <div className="fesbInfoPanel fesbEducatorInfoPanel" ref={service.infoRef} onScroll={service.onScroll}>
                                <nav className="fesbEducatorTabs">
                                    <div className="fesbEducatorTab" onClick={() => service.activeTab_set('cv')}>
                                        {lang.EDUCATORS.CV}
                                    </div>
                                    {service.displayedEducator.bibirbId && (
                                        <div className="fesbEducatorTab" onClick={() => service.activeTab_set('science')}>
                                            {lang.EDUCATORS.science}
                                        </div>
                                    )}
                                    <div className="fesbEducatorTab" onClick={() => service.activeTab_set('classes')}>
                                        {lang.EDUCATORS.classes}
                                    </div>
                                </nav>
                                <div className="fesbEducatorFixedInfo">
                                    {service.displayedEducator.title && (
                                        <div className="fesbEducatorTitle">{service.displayedEducator.title}</div>
                                    )}
                                    <div className="fesbEducatorName">
                                        {`${service.displayedEducator.lastName} ${service.displayedEducator.firstName}`}
                                    </div>
                                    {currentUserType && <div className="fesbEducatorType">{currentUserType}</div>}
                                    <div className="fesbLargeUserFrame">
                                        <img
                                            src={
                                                service.displayedEducator.largePictureLink
                                                    ? convertBufferToImageSrc((service.displayedEducator.largePictureLink as any).data)
                                                    : userPlaceholder
                                            }
                                        ></img>
                                    </div>
                                    {service.displayedEducator[('position_' + currentLang) as 'position_hr'] && (
                                        <div className="fesbEducatorFixedListItem">
                                            <i className="fas fa-briefcase"></i>
                                            {service.displayedEducator[('position_' + currentLang) as 'position_hr']}
                                        </div>
                                    )}
                                    {service.displayedEducator.department && (
                                        <div className="fesbEducatorFixedListItem">
                                            <i className="far fa-building"></i>
                                            {service.displayedEducator.department[('name_' + currentLang) as 'name_hr']}
                                        </div>
                                    )}
                                    {service.displayedEducator.roomCode && (
                                        <div className="fesbEducatorFixedListItem">
                                            <i className="fas fa-map-marker-alt"></i>
                                            {service.displayedEducator.roomCode}
                                        </div>
                                    )}
                                    {service.displayedEducator.email && (
                                        <div className="fesbEducatorFixedListItem">
                                            <i className="far fa-envelope"></i>
                                            <a href={'mailto:' + service.displayedEducator.email}>{service.displayedEducator.email}</a>
                                        </div>
                                    )}
                                    {service.displayedEducator.telephone && (
                                        <div className="fesbEducatorFixedListItem">
                                            <i className="fas fa-phone"></i>
                                            {service.displayedEducator.telephone}
                                        </div>
                                    )}
                                </div>
                                <div className="fesbEducatorScrollingInfo">
                                    {service.activeTab === 'cv' && (
                                        <CVTab displayedEducator={service.displayedEducator} lang={lang} currentLang={currentLang} />
                                    )}
                                    {service.activeTab === 'science' && (
                                        <ScienceTab displayedEducator={service.displayedEducator} lang={lang} currentLang={currentLang} />
                                    )}
                                    {service.activeTab === 'classes' && (
                                        <ClassesTab
                                            displayedEducator={service.displayedEducator}
                                            lang={lang}
                                            settings={service.settings}
                                            currentLang={currentLang}
                                        />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </SpinWhileLoading>
                </div>
            </SpinWhileLoading>
        </div>
    )
}

export default Educators
