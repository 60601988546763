import constants from '../constants'
import routes from '../routes'

const report: Report.Report = (props) => {
    fetch(constants.serverUrl + routes.errorReport, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...props, applicationId: '5f072e324895a32af418a484' }),
    })
}

export const REPORT_PRIORITIES = {
    HIGH: 3 as 3,
    MEDIUM: 2 as 2,
    LOW: 1 as 1,
}

export default report
