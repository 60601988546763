export default {
    courses: '/courses',
    courses_university: '/courses/university',
    courses_professional: '/courses/professional',
    courses_university_undergraduate: '/courses/university/undergraduate',
    courses_university_graduate: '/courses/university/graduate',
    courses_university_postgraduate: '/courses/university/postgraduate',
    courses_difference: '/courses/difference',
    educators: '/educators',
    classes: '/classes',
    classes_all: '/classes/all',
    classes_university: '/classes/university',
    classes_difference: '/classes/difference',
    classes_university_undergraduate: '/classes/university/undergraduate',
    classes_university_graduate: '/classes/university/graduate',
    classes_university_postgraduate: '/classes/university/postgraduate',
    classes_professional: '/classes/professional',
    errorReport: '/util/reportError',
}
