import React from 'react'
import { useLocale } from '../../../Util/Locale/useLocale'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    const { lang } = useLocale<Locale.Keys>()
    return (
        <div className="fesbPageNotFound">
            <div>{lang.pageNotFound}</div>
            <Link to="/" className="returnText">
                {lang.returnToHomePage}
            </Link>
        </div>
    )
}

export default PageNotFound
