import React from 'react'

const ExecutionTab: PublicClient.AcademicClasses.Tab = ({ displayedAcademicClass, lang, settings, currentLang }) => {
    return (
        <div className="fesbAcademicClassTab">
            <h2>{lang.ACADEMIC_CLASSES.language}</h2>
            <div>
                {lang.croatian}
                {displayedAcademicClass?.inEnglish ? `, ${lang.english}` : ''}
            </div>
            <h2>{lang.ACADEMIC_CLASSES.qualityControlMethods}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        displayedAcademicClass![('qualityControlMethods_' + currentLang) as 'qualityControlMethods_hr'] ||
                        `<div>${lang.noData}</div>`,
                }}
            ></div>
            <h2>{lang.ACADEMIC_CLASSES.examProcess}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html: displayedAcademicClass![('examProcess_' + currentLang) as 'examProcess_hr'] || `<div>${lang.noData}</div>`,
                }}
            ></div>
        </div>
    )
}

export default ExecutionTab
