import React, { useState } from 'react'
import { useLocale } from '../../../Util/Locale/useLocale'
import { useSettings } from '../../../Util/Settings/useSettings'
import { useLocation, useHistory } from 'react-router-dom'
import getCanonicalPath from '../../../Util/getCanonicalPath'
import constants from '../../../Util/constants'
import useNavigationTree from '../../DynamicHeader/NavigationTree'

const SearchBox = <T extends { key: string }>(props: PublicClient.SearchBox.Import<T>) => {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    const settings = useSettings().settings
    const location = useLocation()
    const navigationTree = useNavigationTree()
    const canonicalPath = getCanonicalPath(location.pathname, navigationTree)
    const history = useHistory()
    const isMinimized = canonicalPath !== location.pathname && constants.isOnMobile

    return (
        <nav className={'fesbSearchBox' + (isMinimized ? ' minimized' : '')} style={props.style}>
            <input
                className="fesbSearchBoxInput"
                placeholder={lang.filterByNamePlaceholder}
                value={props.internalFilter ? props.internalFilter : ''}
                onChange={(e) => {
                    props.setInternalFilter(e.target.value)
                }}
                onFocus={() => {
                    history.push(canonicalPath)
                }}
            ></input>
            {props.data
                ? props.data.map((listItem) => (
                      <props.listMemberRenderFunction
                          listItem={listItem}
                          key={listItem.key}
                          settings={settings}
                          lang={lang}
                          currentLang={currentLang}
                          currentPath={canonicalPath}
                          onClick={() => {}}
                      />
                  ))
                : 'no data'}
        </nav>
    )
}

export default SearchBox
