export default (settings: PublicClient.Settings.Setting[] | undefined, optionId: number | undefined) => {
    if (settings && optionId) {
        const foundSetting = settings.find((option) => option.mainEntityId === optionId)
        if (!foundSetting) return null
        else {
            return foundSetting
        }
    } else {
        return null
    }
}
