import React from 'react'
import { useLocale } from '../../../Util/Locale/useLocale'

const ClassExecutionPlanHeaderRow: PublicClient.Courses.ExecutionPlan.HeaderRow = (props) => {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    let formattedMethods: { abbreviation: string; isSummary?: boolean }[] = []
    if (props.methods.length) {
        for (let i = 0; i < 8; i++)
            if (!props.methods[i]) formattedMethods.push({ abbreviation: '' })
            else if (i < 7) {
                formattedMethods.push({ ...props.methods[i] })
            } else {
                formattedMethods.push({ abbreviation: '...', isSummary: true })
            }
    }
    return (
        <div className="FesbClassExecutionPlanHeaderRowContainer">
            <div className="FesbClassExecutionPlanHeaderRowTitle">{`${props.semester}${
                currentLang === 'en' ? (props.semester === 1 ? 'st' : 'nd') : '.'
            } ${lang.semester.toLocaleLowerCase()}`}</div>
            <div className="FesbClassExecutionPlanRow FesbClassExecutionPlanHeaderRow">
                <div className="smallBox" style={{ borderRight: 'unset' }}>
                    {lang.code.toLocaleUpperCase()}
                </div>
                <div>{lang.name.toLocaleUpperCase()}</div>
                <div>{lang.headProfessors.toLocaleUpperCase()}</div>
                {formattedMethods.map((method, index) => (
                    <div
                        className="smallBox"
                        key={'SB' + index}
                        style={!method.isSummary && index > 0 ? { borderLeft: '2px solid var(--colorText)' } : undefined}
                    >
                        {method.abbreviation}
                    </div>
                ))}
                <div className="smallBox" style={{ borderRight: 'unset' }}>
                    ECTS
                </div>
            </div>
        </div>
    )
}

export default ClassExecutionPlanHeaderRow
