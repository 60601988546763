export default (): Locale.Keys => ({
    croatian: 'Hrvatski',
    english: 'Engleski',
    course_undergraduate: 'Preddiplomski',
    course_graduate: 'Diplomski',
    course_difference: 'Razlikovni',
    course_integrated: 'Integrirani',
    course_postgraduate: 'Poslijediplomski',
    course_professional: 'Stručni',
    course_university: 'Sveučilišni',
    courses: 'Studiji',
    classes: 'Predmeti',
    pageTitle: 'FESB Nastava',
    educators: 'Profesori',
    classes_all: 'Svi predmeti',
    loading: 'Učitavanje',
    semester: 'Semestar',
    year: 'Godina',
    pageNotFound: 'Stranica nije pronađena',
    returnToHomePage: 'Kliknite da bi ste se vratili na početnu stranicu',
    employees: 'Zaposlenici',
    associates: 'Vanjski suradnici',
    error: 'Došlo je do greške',
    filterByNamePlaceholder: 'Upišite tekst da bi ste filtrirali po imenu',
    name: 'Naziv',
    headProfessors: 'Nositelji',
    code: 'Kód',
    STATIC_HEADER: {
        schedule: 'Raspored',
        program: 'Program',
        enrollment: 'Upisi',
        graduateThesis: 'Završni/Diplomski',
    },
    EDUCATORS: {
        CV: 'Životopis',
        classes: 'Nastava',
        headProfessor: 'Nositelj',
        science: 'Znanost',
        education: 'Obrazovanje',
        institutionOfCurrentEmployment: 'Ustanova trenutnog zaposlenja',
        specializationsAndInternationalCooperation: 'Specijalizacije i međunarodna suradnja',
        scientificArea: 'Znanstveno područje, polje, grana',
        narrowInterestArea: 'Uže područje interesa',
        lastDateOfAppointment: 'Datum zadnjeg izbora u zvanje',
        publishedPapers: 'Objavljeni radovi u Hrvatskoj znanstvenoj bibliografiji (CROSBI)',
        PUBLISHED_PAPERS_TEXT:
            'Ove radove održava Knjižnica Instituta Ruđer Bošković koja vodi projekt Hrvatske znanstvene bibliografije CROSBI. Ovim linkom možete vidjeti sve podatke o radovima koje su autori unijeli u bazu podataka.',
    },
    ACADEMIC_CLASSES: {
        general: 'Općenito',
        content: 'Sadržaj i literatura',
        execution: 'Provedba',
        gradingMethod: 'Način ocjenjivanja',
        classUnits: 'Nastavne jedinice',
        level: 'Level',
        lengthInHours: 'Trajanje u satima',
        nameOfClass: 'Naziv predmeta',
        classCode: 'Šifra predmeta',
        classesAndLecturers: 'Nastava i predavači',
        enrollmentConditions: 'Preduvjeti za upis',
        examProcess: 'Način polaganja ispita',
        goals: 'Ciljevi',
        groups: 'Podjela nastave u grupe',
        resultOfStudy: 'Ishodi učenja',
        qualityControlMethods: 'Način praćenja kvalitete',
        professorBurden: 'Dodjela opterećenja nastavnicima',
        headProfessors: 'Nositelji predmeta',
        assistants: 'Asistenti',
        addExecutionPlan: 'Dodaj izvedbeni plan',
        lectures: 'Predavanja',
        seminars: 'Seminari',
        workshops: 'Radionice',
        exercises: 'Auditorne vježbe',
        online: 'Isključivo online',
        mixedELearning: 'Mješovito e-učenje',
        fieldWork: 'Terenska nastava',
        independentTasks: 'Samostalni zadaci',
        multimedia: 'Multimedija',
        laboratory: 'Laboratorij',
        workWithMentor: 'Mentorski rad',
        other: 'Ostalo',
        methodOfExecution: 'Način izvođenja nastave',
        lengthInWeeks: 'Trajanje u tjednima',
        hoursPerWeek: 'Broj sati u tjednu',
        enterMethod: 'Molimo unesite ime načina izvođenja nastave',
        addClassExecutionPlan: 'Dodaj izvedbeni plan',
        inEnglish: 'Predmet se može slušati na engleskom',
        type: 'Tip',
        suggestedLiterature: 'Preporučena literatura',
        extraLiterature: 'Dodatna literatura',
        addClassUnit: 'Dodaj nastavnu jedinicu',
        language: 'Jezik poduke',
    },
    noData: 'Nema podataka',
    weeks: 'Tjedana',
    hour: 'Sat',
    hours: 'Sata',
    hours2: 'sati',
    hoursPerWeek: 'Sati tjedno',
    specialization: 'Smjer',
    academicYear: 'Akademska godina',
    course: 'Studij',
})
