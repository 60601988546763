import React, { useState, useRef, useEffect } from 'react'

const useCourseListElement: PublicClient.Courses.CourseListElement.Service = () => {
    const [elementHeight, elementHeight_set] = useState<number | undefined>(undefined)
    const [initialHeight, initialHeight_set] = useState<number | undefined>()
    const ref = useRef<HTMLDivElement>(null)
    const onMouseEnter = (e: React.MouseEvent) => {
        if (ref.current) {
            const measurerElement = ref.current.querySelector('.fesbListElementMeasurer')
            if (measurerElement) {
                //if not for this check, empty dropdowns will still appear very slightly due to padding/margin
                const measurement = measurerElement.getBoundingClientRect().height
                if (measurement > 40) elementHeight_set(measurement)
            }
        }
    }
    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                const measurerElement = ref.current.querySelector('.fesbListElementMeasurer>.fesbCourseListElement')
                if (measurerElement) {
                    const measurement = measurerElement.getBoundingClientRect().height
                    elementHeight_set(measurement)
                    initialHeight_set(measurement)
                }
            }
        }, 600) //TODO: this is hacky, find out why the element doesn't have the proper height at start so there's no need to wait. It seems it is animating height even when i turn off transition
    }, [])
    const onMouseLeave = (e: React.MouseEvent) => {
        elementHeight_set(initialHeight)
    }
    return {
        elementHeight,
        onMouseEnter,
        onMouseLeave,
        ref,
    }
}

export default useCourseListElement
