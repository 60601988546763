import React from 'react'
import Color from '../../../../Util/color/Color'
import getOptionType from '../../../../Util/getOptionType'
import { Link, useLocation } from 'react-router-dom'
import getCanonicalPath from '../../../../Util/getCanonicalPath'

const MethodOfExecutionCard: PublicClient.AcademicClasses.MethodOfExecutionCard = ({ settings, executionPlan, lang, currentLang }) => {
    const relevantMethodEntity = getOptionType(settings?.executionMethods, executionPlan.methodOfExecution)
    let titleColor = relevantMethodEntity ? (relevantMethodEntity as any).color : 'var(--colorPrimary)'
    const parsedTitleColor = Color.parse(titleColor)
    const textColor = parsedTitleColor ? parsedTitleColor.blackOrWhite().format('hex') : 'white'
    return (
        <div className="fesbClassExecutionPlanCard">
            <div className="fesbClassExecutionPlanCardLeft">
                <div
                    className="fesbClassExecutionPlanCardTitle"
                    style={{
                        background: `linear-gradient(90deg, ${titleColor} 0%, ${titleColor} 65%, var(--colorWhiteDarkBackground) 100%)`,
                        color: textColor,
                    }}
                >
                    {' '}
                    {currentLang === 'en' ? relevantMethodEntity?.title_en : relevantMethodEntity?.title_hr}
                </div>
                <div className="fesbClassExecutionPlanCardProfessors">
                    {executionPlan.professors.map((professor) => (
                        <Link key={'ECPK-' + professor.mainEntityId} to={'/educators/employees/user-' + professor.mainEntityId}>
                            {professor.title ? `${professor.title} ` : null}
                            {`${professor.firstName} ${professor.lastName}`}
                        </Link>
                    ))}
                </div>
            </div>
            <div className="fesbClassExecutionPlanCardRight">
                <div className="fesbClassExecutionPlanCardContent">
                    <div>{`${executionPlan.lengthInWeeks * executionPlan.hoursPerWeek} ${
                        executionPlan.lengthInWeeks * executionPlan.hoursPerWeek > 5
                            ? lang.hours2.toLocaleLowerCase()
                            : executionPlan.lengthInWeeks * executionPlan.hoursPerWeek === 1
                            ? lang.hour
                            : lang.hours
                    }`}</div>
                    <div>{`${executionPlan.hoursPerWeek} ${lang.hoursPerWeek} × ${executionPlan.lengthInWeeks} ${lang.weeks}`}</div>
                </div>
            </div>
        </div>
    )
}

export default MethodOfExecutionCard
