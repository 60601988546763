import { useState, useEffect } from 'react'
import { useSettings } from '../../Util/Settings/useSettings'
import AcademicClassesApi from './AcademicClassesApi'
import { useLocation } from 'react-router-dom'
import { useLocale } from '../../Util/Locale/useLocale'

const useAcademicClasses: PublicClient.AcademicClasses.Service = ({ navigationOptions }) => {
    const { currentLang } = useLocale<Locale.Keys>()
    const [dataLoaded, dataLoaded_set] = useState<boolean>(false)
    const [dataLoading, dataLoading_set] = useState<boolean>(false)
    const [listLoaded, listLoaded_set] = useState<boolean>(false)
    const settings = useSettings().settings
    const [displayedAcademicClass, displayedAcademicClass_set] = useState<PublicClient.AcademicClasses.API.GetById.Data | undefined>()
    const [list, list_set] = useState<PublicClient.AcademicClasses.Service.ListMember[]>([])
    const [filters, filters_set] = useState<PublicClient.AcademicClasses.API.GetList.Filters>()
    const [internalFilter, internalFilter_set] = useState<string | undefined>()
    const [locallyFilteredList, locallyFilteredList_set] = useState<PublicClient.AcademicClasses.Service.ListMember[]>([])
    const [activeTab, activeTab_set] = useState<PublicClient.AcademicClasses.Service.AvailableTabs>('general')
    const location = useLocation()
    const [offsetTop, offsetTop_set] = useState<boolean>(false)
    const [offsetBottom, offsetBottom_set] = useState<boolean>(false)

    const getList = async (filters?: PublicClient.AcademicClasses.API.GetList.Filters) => {
        const fetchedList = await AcademicClassesApi.getList(filters)
        if (fetchedList) {
            list_set(
                fetchedList.map((listMember) => {
                    return { ...listMember, key: 'k' + listMember.courseClassMainEntityId }
                })
            )
        }
        listLoaded_set(true)
    }
    const loadCourseClass = async () => {
        const locationParams = location.pathname.split('/')
        const courseClassParameter = locationParams[locationParams.length - 1]
        if (courseClassParameter.slice(0, 11) === 'courseClass') {
            const courseClassId = Number(courseClassParameter.slice(12))
            dataLoading_set(true)
            const fetchedData = await AcademicClassesApi.getById(courseClassId)
            if (fetchedData) {
                displayedAcademicClass_set(fetchedData)
                dataLoaded_set(true)
                dataLoading_set(false)
            } else {
                dataLoaded_set(false)
                dataLoading_set(false)
            }
        }
    }

    const onScroll = (e: any) => {
        if ((e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) === offsetBottom) offsetBottom_set(!offsetBottom)
        if (e.target.scrollTop && !offsetTop) offsetTop_set(true)
        else if (!e.target.scrollTop && offsetTop) offsetTop_set(false)
    }

    useEffect(() => {
        loadCourseClass()
    }, [location.pathname])

    useEffect(() => {
        let attempts = 0
        offsetBottom_set(false)
        offsetTop_set(false)
        let currentTimeout: NodeJS.Timeout | undefined
        const waitForRender = () => {
            const scrollElement = document.querySelector('.fesbAcademicClassesContent')
            if (scrollElement) {
                if (scrollElement.scrollHeight > scrollElement.clientHeight !== offsetBottom) offsetBottom_set(!offsetBottom)
                if (scrollElement.scrollTop && !offsetTop) offsetTop_set(true)
                else if (!scrollElement.scrollTop && offsetTop) offsetTop_set(false)
            } else if (attempts < 20) {
                attempts++
                currentTimeout = setTimeout(waitForRender, 500)
            }
        }
        waitForRender()
        return () => currentTimeout && clearTimeout(currentTimeout)
    }, [activeTab, location.pathname])

    useEffect(() => {
        if (settings && navigationOptions[1] !== undefined && navigationOptions[2] !== undefined) {
            const newFilters: PublicClient.AcademicClasses.API.GetList.Filters = {}

            if (navigationOptions[1]) newFilters.courseType = navigationOptions[1]
            if (navigationOptions[2]) newFilters.courseLevel = navigationOptions[2]
            filters_set(newFilters)
        } else if (settings) {
            if (!filters || Object.keys(filters).length) filters_set({})
        }
    }, [navigationOptions[1], navigationOptions[2], settings])
    useEffect(() => {
        if (filters) {
            getList(filters)
        }
    }, [filters])
    useEffect(() => {
        if (list) {
            if (internalFilter) {
                const newList = list.filter((listItem) =>
                    listItem[('name_' + currentLang) as 'name_hr'].toLocaleLowerCase().includes(internalFilter.toLocaleLowerCase())
                )
                locallyFilteredList_set(newList)
            } else locallyFilteredList_set(list)
        }
    }, [internalFilter, list])

    return {
        dataLoaded,
        dataLoading,
        settings,
        locallyFilteredList,
        listLoaded,
        internalFilter,
        internalFilter_set,
        displayedAcademicClass,
        activeTab,
        activeTab_set,
        onScroll,
        offsetTop,
        offsetBottom,
    }
}

export default useAcademicClasses
