import React from 'react'
import SpinWhileLoading from '../../Common/SpinnWhileLoading/SpinnWhileLoading'

const CVTab: PublicClient.Educators.Tab = ({ displayedEducator, lang, currentLang }) => {
    return (
        <SpinWhileLoading dataLoaded={displayedEducator ? true : false}>
            <main className="fesbEducatorContent">
                <h2 style={{ marginTop: '0' }}>{lang.EDUCATORS.education}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: displayedEducator![('education_' + currentLang) as 'education_hr'] || `<div>${lang.noData}</div>`,
                    }}
                ></div>
                <h2>{lang.EDUCATORS.institutionOfCurrentEmployment}</h2>
                {displayedEducator![('employerInstitution_' + currentLang) as 'employerInstitution_hr'] || lang.noData}
                <h2>{lang.EDUCATORS.specializationsAndInternationalCooperation}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            displayedEducator![('specializations_' + currentLang) as 'specializations_hr'] || `<div>${lang.noData}</div>`,
                    }}
                ></div>
                <h2>{lang.EDUCATORS.scientificArea}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: displayedEducator![('scientificArea_' + currentLang) as 'scientificArea_hr'] || `<div>${lang.noData}</div>`,
                    }}
                ></div>
                <h2>{lang.EDUCATORS.narrowInterestArea}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: displayedEducator![('areaOfInterest_' + currentLang) as 'areaOfInterest_hr'] || `<div>${lang.noData}</div>`,
                    }}
                ></div>
                <h2>{lang.EDUCATORS.lastDateOfAppointment}</h2>
                {displayedEducator!.positionElectionDate
                    ? `${new Date(displayedEducator!.positionElectionDate).getDate()}/${new Date(
                          displayedEducator!.positionElectionDate
                      ).getMonth()}/${new Date(displayedEducator!.positionElectionDate).getFullYear()}`
                    : lang.noData}
            </main>
        </SpinWhileLoading>
    )
}

export default CVTab
