import { useState, useEffect } from 'react'
import { useLocale } from '../../Util/Locale/useLocale'
import constants from '../../Util/constants'
import calculateHeaderElementPositions from '../../Util/calculateHeaderElementPositions'
import useNavigationTree from './NavigationTree'

const useDynamicHeader: PublicClient.DynamicHeader.Service = (props) => {
    const { lang } = useLocale<Locale.Keys>()
    const [firstRender, firstRender_set] = useState<boolean>(true)
    const [optionData, optionData_set] = useState<PublicClient.DynamicHeader.Service.OptionData[][]>([])
    const navigationTree = useNavigationTree()

    useEffect(() => {
        const assignOptionsFromTree = () => {
            let newOptionData: PublicClient.DynamicHeader.Service.OptionData[][] = []
            let tempTree: PublicClient.DynamicHeader.NavigationTree | null = { ...navigationTree }
            const result = assignChosenOptions(tempTree, lang, props.chosenOptions)
            if (result) {
                tempTree = result.tree
                newOptionData = result.optionsToReturn
                if (tempTree) {
                    const currentLevelOptions = assignActiveOptions(tempTree, lang, result.numberOfHeaderRows)
                    newOptionData.push(currentLevelOptions)
                }
                optionData_set(newOptionData)
            }
        }
        assignOptionsFromTree()
        window.addEventListener('resize', assignOptionsFromTree)
        return () => window.removeEventListener('resize', assignOptionsFromTree)
    }, [props.chosenOptions, lang, navigationTree])
    useEffect(() => {
        if (firstRender) firstRender_set(false)
    }, [firstRender])
    return {
        optionData,
    }
}

export default useDynamicHeader

const assignActiveOptions = (
    tree: PublicClient.DynamicHeader.NavigationTree,
    lang: Locale.Keys,
    numberOfHeaderRows: number,
    isRepeat?: boolean
): PublicClient.DynamicHeader.Service.OptionData[] => {
    let optionsToReturn: PublicClient.DynamicHeader.Service.OptionData[] = []
    let i = 0
    for (let [key, value] of Object.entries(tree)) {
        let position: { top: number; left: number; height: number; width: number }
        let calculationResult = calculatePosition(
            Object.keys(tree as PublicClient.DynamicHeader.NavigationTree).length,
            i,
            numberOfHeaderRows
        )
        //if the calculation returns null, this means the width has changed mid calculation and positioning needs to be redone
        if (!calculationResult) {
            if (isRepeat) return []
            return assignActiveOptions(tree, lang, numberOfHeaderRows)
        }
        position = calculationResult
        const newOption: PublicClient.DynamicHeader.Service.OptionData = {
            optionId: Number(key),
            title: value.title,
            to: value.linkTo,
            isAnimated: true,
            position,
            imageUrl: value.imageUrl,
            classes: 'activeCard',
        }
        optionsToReturn.push(newOption)
        i++
    }
    return optionsToReturn
}

const assignChosenOptions = (
    tree: PublicClient.DynamicHeader.NavigationTree | null,
    lang: Locale.Keys,
    chosenOptions: number[],
    isRepeat?: boolean
): {
    optionsToReturn: PublicClient.DynamicHeader.Service.OptionData[][]
    tree: PublicClient.DynamicHeader.NavigationTree | null
    numberOfHeaderRows: number
} | null => {
    let optionsToReturn: PublicClient.DynamicHeader.Service.OptionData[][] = []
    let numberOfHeaderRows = 0
    try {
        //first traverse the tree, assigning all options as if they were hidden items but returning chosen options into the itemsToAssign array for further processing
        for (const chosenOption of chosenOptions) {
            /**
             * each member of the chosenOptions array represents one chosen navigational option which needs to be
             * rendered as a tool bar segment while the other options on that level of the navigation tree need to be
             * hidden away. The reason they are hidden and not removed entirely is for animation purposes.
             */
            let currentLevelOptionsToReturn: PublicClient.DynamicHeader.Service.OptionData[] = []
            for (let [key, value] of Object.entries(tree as PublicClient.DynamicHeader.NavigationTree)) {
                let position: { top: number; left: number; height: number; width: number }
                if (Number(key) === chosenOption) {
                    let calculationResult = calculatePosition(
                        Object.keys(tree as PublicClient.DynamicHeader.NavigationTree).length,
                        Number(key),
                        0
                    )
                    //if the calculation returns null, this means the width has changed mid calculation and positioning needs to be redone
                    if (!calculationResult) {
                        if (isRepeat) return null
                        return assignChosenOptions(tree, lang, chosenOptions, isRepeat)
                    }
                    position = calculationResult
                } else {
                    position = { top: 0, left: 0, height: constants.splashCardSize, width: constants.splashCardSize }
                }
                const newOption: PublicClient.DynamicHeader.Service.OptionData = {
                    optionId: Number(key),
                    title: value.title,
                    to: value.linkTo,
                    //don't assign positions to chosen items, this will be done further down the line
                    position, //TODO: Potentially just hardcode position for hidden items, performance optimization but will hinder outgoing animations, should be restricted to items that were hidden before this render
                    imageUrl: value.imageUrl,
                    isAnimated: false,
                    classes: Number(key) === chosenOption ? 'fesbHeaderBarOption' : 'fesbHiddenSplashCard',
                }
                currentLevelOptionsToReturn[value.id] = newOption
            }
            optionsToReturn.push(currentLevelOptionsToReturn)
            //after assigning relevant data to the options, we move on to the next choice (next branch of the tree)
            const traverseResult = traverseTree(tree as PublicClient.DynamicHeader.NavigationTree, [chosenOption])
            tree = traverseResult
            if (!tree) break
        }
        //now we calculate the positions of the chosen (toolbar) elements and assign them
        //first get the texts of those options, these are needed to calculate the sizes of the toolbar elements
        const chosenOptionTexts = optionsToReturn.map((optionsToReturnLevel, index) => {
            return { text: optionsToReturnLevel[chosenOptions[index]].title }
        })
        //now use those to calculate the positions
        const { calculatedElementPositions, numberOfRows } = calculateHeaderElementPositions(chosenOptionTexts)
        //for the mobile layout, set number of rows to -1 so that the marginTop value in the next calculation will be further reduced when there is no header and eliminate empty space
        numberOfHeaderRows = numberOfRows ? numberOfRows : constants.isOnMobile ? -1 : 0

        //The view needs to be adjusted so that the content doesn't cover the header, this is mainly a mobile concern.
        const viewElement: HTMLDivElement | null = document.querySelector('.fesbView')
        if (viewElement) {
            const marginTop =
                constants.mainHeaderHeight + constants.dynamicHeaderTopOffset * 2 + numberOfRows * constants.dynamicHeaderHeight * 1.1
            viewElement.style.marginTop = '' + marginTop + 'px'
            const BOTTOM_OFFSET = constants.isOnMobile ? 20 : 110
            viewElement.style.maxHeight = '' + (window.innerHeight - marginTop - Math.max(BOTTOM_OFFSET, constants.logoHeight)) + 'px'
        }
        //assign positions to the object array that will be returned
        calculatedElementPositions.forEach((chosenOptionPosition, index) => {
            //assign positions to every element on the same level of the tree, this is to smooth out animations so that, when the hidden elements
            //are revealed (through later page navigation), they don't slide in from x=0, y=0 but, rather, appear right where they should be
            optionsToReturn[index].forEach((option) => (option.position = chosenOptionPosition))
        })
    } catch (error) {
        console.log('===error===', error)
        return null
    }
    return { optionsToReturn, tree, numberOfHeaderRows }
}

const calculatePosition = (
    numberOfItems: number,
    index: number,
    numberOfHeaderRows: number
): { top: number; left: number; height: number; width: number } | null => {
    const OFFSET_FROM_TOP = constants.mainHeaderHeight + constants.dynamicHeaderTopOffset
    const TOP_PADDING = constants.dynamicHeaderHeight * numberOfHeaderRows
    const availableWidth = document.body.clientWidth
    const itemsInRow = Math.max(Math.floor(availableWidth / (constants.splashCardSize * constants.splashCardAreaOccupiedFactor)), 2)
    let actualSplashCardSize = constants.splashCardSize
    const numberOfRows = Math.ceil(numberOfItems / itemsInRow)
    if (index === numberOfItems - 1) {
        const body = document.body
        if (
            numberOfRows * actualSplashCardSize * constants.splashCardAreaOccupiedFactor + OFFSET_FROM_TOP + TOP_PADDING >
            window.innerHeight
        ) {
            if (body)
                body.style.minHeight =
                    '' +
                    (numberOfRows * actualSplashCardSize * constants.splashCardAreaOccupiedFactor + TOP_PADDING + OFFSET_FROM_TOP + 100) +
                    'px'
        } else {
            if (body) body.style.minHeight = '100vh'
        }
    }
    if (availableWidth !== document.body.clientWidth) {
        //this means that the scroll bar has just appeared or disappeared because of the increased body height. The calculations need to be redone
        return null
    }
    const currentRow = Math.ceil((index + 1) / itemsInRow)
    const requiredWidth = actualSplashCardSize * constants.splashCardAreaOccupiedFactor * itemsInRow
    const availableHeight = window.innerHeight - OFFSET_FROM_TOP - TOP_PADDING
    const requiredHeight = numberOfRows * actualSplashCardSize * constants.splashCardAreaOccupiedFactor
    let top
    if (availableHeight > requiredHeight) {
        //vertically center items on the free part of the screen
        const availableHeightCenter = OFFSET_FROM_TOP + TOP_PADDING + availableHeight / 2
        top =
            availableHeightCenter -
            0.5 * requiredHeight +
            (currentRow - 0.5) * actualSplashCardSize * constants.splashCardAreaOccupiedFactor
    } else {
        //don't center vertically
        top = OFFSET_FROM_TOP + TOP_PADDING + (currentRow - 0.5) * actualSplashCardSize * constants.splashCardAreaOccupiedFactor
    }
    let left
    if (currentRow < numberOfRows) {
        left =
            0.5 * (availableWidth - requiredWidth) +
            ((index % itemsInRow) + 0.5) * actualSplashCardSize * constants.splashCardAreaOccupiedFactor
    } else {
        const requiredWidth =
            actualSplashCardSize * constants.splashCardAreaOccupiedFactor * (numberOfItems - (numberOfRows - 1) * itemsInRow)
        left =
            0.5 * (availableWidth - requiredWidth + actualSplashCardSize * constants.splashCardAreaOccupiedFactor) +
            (index % itemsInRow) * actualSplashCardSize * constants.splashCardAreaOccupiedFactor
    }
    return { top, left, height: constants.splashCardSize, width: constants.splashCardSize }
}
export const traverseTree = (
    currentTree: PublicClient.DynamicHeader.NavigationTree,
    keys: number[]
): PublicClient.DynamicHeader.NavigationTree | null => {
    if (keys.length === 1) return currentTree[keys[0]].options as PublicClient.DynamicHeader.NavigationTree
    else if (currentTree[keys[0]].options) {
        return traverseTree(currentTree[keys[0]].options as PublicClient.DynamicHeader.NavigationTree, keys.slice(1))
    } else return null
}

export const getChosenOptionsFromPath = (path: string, tree: PublicClient.DynamicHeader.NavigationTree) => {
    let tempTree: PublicClient.DynamicHeader.NavigationTree | null = { ...tree }
    const pathNameArray = path.split('/').slice(1)
    if (pathNameArray.length === 1 && pathNameArray[0] === '')
        return {
            chosenOptions: [],
            navigationDone: false,
        }
    const chosenOptions: number[] = []
    let currentPath = ''
    let navigationDone = false
    for (const choice of pathNameArray) {
        currentPath += '/' + choice
        const foundOption = Object.entries(tempTree!).find(([key, value]) => {
            return value.linkTo === currentPath
        })
        if (!foundOption) {
            console.log('could not map url to options')
            return null
        }
        const currentChoice = foundOption[1].id
        chosenOptions.push(currentChoice)
        tempTree = traverseTree(tempTree!, [currentChoice])
        if (!tempTree) {
            navigationDone = true
            break
        }
    }
    return {
        chosenOptions,
        navigationDone,
    }
}
