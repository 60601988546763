export default (): Locale.Keys => ({
    croatian: 'Croatian',
    english: 'English',
    course_undergraduate: 'Undergraduate',
    course_graduate: 'Graduate',
    course_difference: 'Difference',
    course_integrated: 'Integrated',
    course_postgraduate: 'Postgraduate',
    course_professional: 'Professional',
    pageTitle: 'FESB Courses',
    course_university: 'University',
    courses: 'Courses',
    classes: 'Classes',
    error: 'An error has occurred',
    educators: 'Educators',
    classes_all: 'All classes',
    loading: 'Loading',
    filterByNamePlaceholder: 'Write here to filter by name',
    pageNotFound: 'Page not found',
    returnToHomePage: 'Click here to return to the home page',
    semester: 'Semester',
    year: 'Year',
    name: 'Name',
    employees: 'Employees',
    associates: 'Associates',
    headProfessors: 'Head professors',
    code: 'Code',
    STATIC_HEADER: {
        schedule: 'Schedule',
        program: 'Program',
        enrollment: 'Enrollment',
        graduateThesis: 'Graduate thesis',
    },
    EDUCATORS: {
        CV: 'CV',
        headProfessor: 'Head professor',
        classes: 'Classes',
        science: 'Science',
        education: 'Education',
        institutionOfCurrentEmployment: 'Institution of current employment',
        specializationsAndInternationalCooperation: 'Specializations and international cooperation',
        scientificArea: 'Scientific area, field, branch',
        narrowInterestArea: 'Narrow are of interest',
        lastDateOfAppointment: 'Date of last appointment to position',
        publishedPapers: 'Papers published in the Croatian scientific bibliography (CROSBI)',
        PUBLISHED_PAPERS_TEXT:
            'These papers are maintained by the Library of the Ruđer Bošković Institute which leads the Croatian scientific bibliography project (CROSBI). Using this link you can see all the data about the papers entered into the database by the authors.',
    },
    ACADEMIC_CLASSES: {
        general: 'General',
        content: 'Contents and literature',
        execution: 'Execution',
        gradingMethod: 'Grading method',
        classUnits: 'Class units',
        level: 'Level',
        lengthInHours: 'Length in hours',
        nameOfClass: 'Class name',
        classCode: 'Class code',
        enrollmentConditions: 'Enrollment conditions',
        examProcess: 'Exam taking process',
        goals: 'Goals',
        groups: 'Division into groups',
        resultOfStudy: 'Results of completing the class',
        qualityControlMethods: 'Quality control methods',
        professorBurden: 'Burden on professors',
        headProfessors: 'Head professors',
        assistants: 'Assistants',
        addExecutionPlan: 'Add execution plan',
        lectures: 'Lectures',
        seminars: 'Seminars',
        workshops: 'Workshops',
        exercises: 'Auditory exercises',
        online: 'Exclusively online',
        mixedELearning: 'Mixed e-learning',
        fieldWork: 'Field work',
        independentTasks: 'Independent tasks',
        multimedia: 'Multimedia',
        laboratory: 'Laboratory',
        workWithMentor: 'Working with a mentor',
        other: 'Other',
        methodOfExecution: 'Type',
        lengthInWeeks: 'Length in weeks',
        hoursPerWeek: 'Hours per week',
        enterMethod: 'Please enter a name for the type',
        addClassExecutionPlan: 'Add execution plan',
        inEnglish: 'Can be taken in English',
        type: 'Type',
        classesAndLecturers: 'Classes and lecturers',
        suggestedLiterature: 'Suggested literature',
        extraLiterature: 'Extra literature',
        addClassUnit: 'Add class unit',
        language: 'Lecture language',
    },
    noData: 'No data',
    weeks: 'Weeks',
    hour: 'Hour',
    hours2: 'Hours',
    hours: 'Hours',
    hoursPerWeek: 'Hours per week',
    specialization: 'Specialization',
    academicYear: 'Academic year',
    course: 'Course',
})
