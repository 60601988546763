import React from 'react'
import useDynamicHeader from './useDynamicHeader'
import SplashCard from '../Common/SplashCard/SplashCard'
import moveSplashCardToHeader from '../../Util/moveSplashCardToHeader'

const DynamicHeader: PublicClient.DynamicHeader = (props) => {
    const service = useDynamicHeader(props)
    return (
        <div className="fesbDynamicHeader">
            {service.optionData.map((stageData, stageIndex) =>
                stageData.map((optionData) => {
                    let to
                    let onClick
                    let backgroundImageUrl
                    if (optionData.classes && optionData.classes.includes('fesbHeaderBarOption')) {
                        onClick = () => {}
                        to = '/' + optionData.to.split('/').slice(1, -1).join('/')
                        backgroundImageUrl = undefined
                    } else {
                        onClick = () => moveSplashCardToHeader(`fdhsc-${stageIndex}-${optionData.optionId}`)
                        to = optionData.to
                        backgroundImageUrl = optionData.imageUrl
                    }
                    return (
                        <SplashCard
                            key={`fdhsc-${stageIndex}-${optionData.optionId}`}
                            title={optionData.title}
                            class={optionData.classes + (optionData.isAnimated ? ' animated' : '')}
                            backgroundImageUrl={backgroundImageUrl}
                            id={`fdhsc-${stageIndex}-${optionData.optionId}`}
                            to={to}
                            position={optionData.position}
                            onClick={onClick}
                        />
                    )
                })
            )}
        </div>
    )
}

export default DynamicHeader
