import routes from '../../Util/routes'
import ProfessorImage from '../../Resources/professor.jpg'
import ClassImage from '../../Resources/class.jpg'
import CourseImage from '../../Resources/course.jpg'
import Undergraduate from '../../Resources/Fotolia_66365936_Subscription_XXL.jpg'
import Graduate from '../../Resources/unnamed.png'
import Postgraduate from '../../Resources/postgraduate.jpg'
import Difference from '../../Resources/difference.jpg'
import Professional from '../../Resources/professional.webp'
import University from '../../Resources/university.jpg'
import Employees from '../../Resources/employees.jpg'
import Associates from '../../Resources/associates.jpg'
import Classroom from '../../Resources/classroom.jpg'
import { useSettings } from '../../Util/Settings/useSettings'
import { useState, useEffect } from 'react'
import { useLocale } from '../../Util/Locale/useLocale'

const useNavigationTree = () => {
    const { settings } = useSettings()
    const { currentLang, lang } = useLocale<Locale.Keys>()
    const COURSE_IMAGES = {
        undergraduate: Undergraduate,
        professional: Professional,
        university: University,
        graduate: Graduate,
        postgraduate: Postgraduate,
        difference: Difference,
    }
    const createBaseLevelOfNavTree = () => {
        return {
            0: {
                title: lang.courses,
                linkTo: routes.courses,
                imageUrl: CourseImage,
                id: 0,
                options: {},
            },
            1: {
                title: lang.educators,
                linkTo: routes.educators,
                imageUrl: ProfessorImage,
                id: 1,
                options: {
                    0: {
                        title: lang.employees,
                        linkTo: '/educators/employees',
                        id: 0,
                        imageUrl: Employees,
                    },
                    1: {
                        title: lang.associates,
                        linkTo: '/educators/associates',
                        id: 1,
                        imageUrl: Associates,
                    },
                },
            },
            2: {
                title: lang.classes,
                linkTo: routes.classes,
                id: 2,
                imageUrl: ClassImage,
                options: {
                    0: {
                        title: lang.classes_all,
                        id: 0,
                        linkTo: routes.classes_all,
                        imageUrl: Classroom,
                    },
                },
            },
        }
    }
    const [navigationTree, navigationTree_set] = useState<PublicClient.DynamicHeader.NavigationTree>(() => createBaseLevelOfNavTree())
    useEffect(() => {
        if (settings) {
            let newNavTree: PublicClient.DynamicHeader.NavigationTree = createBaseLevelOfNavTree()
            let i = 0
            for (const courseType of settings.courseTypes) {
                ;(newNavTree[0] as any).options[courseType.mainEntityId] = {
                    linkTo: '/courses/' + courseType.title_en.toLocaleLowerCase(),
                    title: (courseType as any)['title_' + currentLang],
                    id: courseType.mainEntityId,
                    imageUrl: (COURSE_IMAGES as any)[courseType.title_en.toLocaleLowerCase()]
                        ? (COURSE_IMAGES as any)[courseType.title_en.toLocaleLowerCase()]
                        : undefined,
                }
                ;(newNavTree[2] as any).options[courseType.mainEntityId] = {
                    linkTo: '/classes/' + courseType.title_en.toLocaleLowerCase(),
                    title: (courseType as any)['title_' + currentLang],
                    id: courseType.mainEntityId,
                    imageUrl: (COURSE_IMAGES as any)[courseType.title_en.toLocaleLowerCase()]
                        ? (COURSE_IMAGES as any)[courseType.title_en.toLocaleLowerCase()]
                        : undefined,
                }
                if (courseType.allowedLevels) {
                    const options = {}
                    courseType.allowedLevels.forEach((id) => {
                        const courseLevel = settings.courseLevels.find((cl) => cl.mainEntityId === id)
                        if (!courseLevel) throw new Error('Invalid course level id')
                        ;(options as any)[courseLevel.mainEntityId] = {
                            linkTo: '/courses/' + courseType.title_en.toLocaleLowerCase() + '/' + courseLevel.title_en.toLocaleLowerCase(),
                            title: (courseLevel as any)['title_' + currentLang],
                            id: courseLevel.mainEntityId,
                            imageUrl: (COURSE_IMAGES as any)[courseLevel.title_en.toLocaleLowerCase()]
                                ? (COURSE_IMAGES as any)[courseLevel.title_en.toLocaleLowerCase()]
                                : undefined,
                        }
                    })
                    ;(newNavTree[0] as any).options[courseType.mainEntityId].options = options
                    ;(newNavTree[2] as any).options[courseType.mainEntityId].options = options
                }
                i++
            }
            navigationTree_set(newNavTree)
        }
    }, [settings, lang])

    return navigationTree
}

export default useNavigationTree
