import constants from './constants'

export default (elements: { text: string }[]) => {
    const TOP_OFFSET = constants.mainHeaderHeight + constants.dynamicHeaderTopOffset
    let availableWidth = document.body.clientWidth
    const LEFT_OFFSET = Math.max((availableWidth - 1300) / 2, 0.05 * availableWidth)
    const ELEMENT_HEIGHT = constants.dynamicHeaderHeight
    const calculatedElementWidths: number[] = []
    for (const element of elements) {
        const elementToMeasure = document.createElement('div')
        elementToMeasure.classList.add('fesbSplashCard')
        elementToMeasure.classList.add('fesbHeaderBarOptionTest')
        elementToMeasure.style.left = '299px'
        elementToMeasure.style.top = '222px'
        const contents = document.createElement('div')
        contents.classList.add('fesbSplashCardContent')
        const overlay = document.createElement('div')
        overlay.classList.add('fesbSplashCardOverlay')
        const title = document.createElement('h1')
        title.classList.add('fesbSplashCardTitle')
        title.style.whiteSpace = 'nowrap'
        title.innerHTML = element.text
        overlay.appendChild(title)
        contents.appendChild(overlay)
        elementToMeasure.appendChild(contents)
        document.body.appendChild(elementToMeasure)
        const renderedElement = document.querySelector('.fesbHeaderBarOptionTest')
        if (renderedElement) {
            const elementContents = renderedElement.querySelector('.fesbSplashCardContent')
            if (!elementContents) throw new Error('dev')
            const width = elementContents.getBoundingClientRect().width
            calculatedElementWidths.push(width)
            document.body.removeChild(renderedElement)
        }
    }
    let calculatedElementPositions: { left: number; top: number; width: number; height: number }[] = []
    let usedWidth = LEFT_OFFSET
    let row = 0
    const MARGIN = 8
    for (const elementWidth of calculatedElementWidths) {
        if (usedWidth > 0 && elementWidth + usedWidth > availableWidth) {
            row++
            usedWidth = LEFT_OFFSET
        }
        calculatedElementPositions.push({
            left: usedWidth + elementWidth / 2,
            top: TOP_OFFSET + ELEMENT_HEIGHT / 2 + row * (ELEMENT_HEIGHT + MARGIN),
            width: elementWidth,
            height: ELEMENT_HEIGHT,
        })
        usedWidth += elementWidth + MARGIN
    }
    return { calculatedElementPositions, numberOfRows: calculatedElementWidths.length ? row + 1 : 0 }
}
